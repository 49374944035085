import React, { memo, Fragment } from "react";

//React-bootstrap
import { Image, Card } from "react-bootstrap";

//Router
import { Link } from "react-router-dom";

import { useMyContext } from "../../../../../Context/MyContextProvider";
const FeatureEventCard = memo((props) => {
  const { createSlug } = useMyContext()
  return (
    <Fragment>
      <Link to={`/events/${createSlug(props.city)}/${createSlug(props?.userName)}/${createSlug(props?.productName)}/${props?.id}`}>
        <div className="animate:hover-media">
          <div className="iq-product-hover-img position-relative animate:hover-media-wrap rounded-4">
            <Image
              loading="lazy"
              src={props.productImage}
              alt="product-details"
              className="img-fluid iq-product-img hover-media"
            />
            <div className="d-flex justify-content-start">
              {props.statusDetails && (
                <div className={`iq-ribbon-effect text-primary ${props.statusColor}`}>
                  <span>{'Sale'}</span>
                </div>
              )}
              {props.isPromoted && (
                <div className={`iq-ribbon-effect text-danger ${props.statusColor}`}
                  style={
                    {
                      marginLeft: props.statusDetails ? '3rem' : '0'
                    }
                  }>
                  <span>{'Promoted'}</span>
                </div>
              )}
              {props.isFree && (
                <div className={`iq-ribbon-effect text-danger ${props.statusColor}`} style={
                  {
                    marginLeft: (props.statusDetails && props.isPromoted) ? '6rem' : (props.statusDetails || props.isPromoted) && '3rem'
                  }
                }>
                  <span>{'Free'}</span>
                </div>
              )}
              {props.isRecommended && (
                <div className={`iq-ribbon-effect text-danger ${props.statusColor}`} style={
                  {
                    marginLeft: (props.statusDetails && props.isPromoted && props.isFree) ? '9rem' :
                      ((props.statusDetails && props.isPromoted)
                        ||
                        (props.statusDetails && props.isFree)
                        ||
                        (props.isPromoted && props.isFree)
                      ) ? '6rem' :
                        (props.statusDetails || props.isPromoted || props.isFree) ? '3rem' : '0'
                  }
                }>
                  <span>{'Recommended'}</span>
                </div>
              )}
            </div>
          </div>
          <span className="h6 iq-product-detail mb-0">
            <Card.Body className="p-0 px-1 py-2">
              <div className="d-flex justify-content-between align-items-center flex-column mb-1">
                {props.productName}
              </div>
              <div className="d-flex justify-content-center align-items-center">
                {
                  props.isSoldOut ?
                    <p className="text-danger">Booking Closed</p>
                    :
                    props.isNotStart ?
                      <p className="text-danger">Booking Not Started Yet</p>
                      :
                      <div className="d-flex gap-1 ">
                        {/* <Link className="btn btn-secondary d-flex align-items-center gap-2">
                      <span className="btn-inner d-flex ">
                        <svg
                          width="20"
                          className="icon-20"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            opacity="0.4"
                            d="M16.6203 22H7.3797C4.68923 22 2.5 19.8311 2.5 17.1646V11.8354C2.5 9.16894 4.68923 7 7.3797 7H16.6203C19.3108 7 21.5 9.16894 21.5 11.8354V17.1646C21.5 19.8311 19.3108 22 16.6203 22Z"
                            fill="currentColor"
                          ></path>
                          <path
                            d="M15.7551 10C15.344 10 15.0103 9.67634 15.0103 9.27754V6.35689C15.0103 4.75111 13.6635 3.44491 12.0089 3.44491C11.2472 3.44491 10.4477 3.7416 9.87861 4.28778C9.30854 4.83588 8.99272 5.56508 8.98974 6.34341V9.27754C8.98974 9.67634 8.65604 10 8.24487 10C7.8337 10 7.5 9.67634 7.5 9.27754V6.35689C7.50497 5.17303 7.97771 4.08067 8.82984 3.26285C9.68098 2.44311 10.7814 2.03179 12.0119 2C14.4849 2 16.5 3.95449 16.5 6.35689V9.27754C16.5 9.67634 16.1663 10 15.7551 10Z"
                            fill="currentColor"
                          ></path>
                        </svg>
                      </span>
                      Book Now
                    </Link> */}
                        {
                          props.statusDetails && Number(props.productPrice) > Number(props.salePrice) ?
                            <>
                              <h6 className="mb-0">
                                {'₹' + props.salePrice}
                              </h6>
                              <p className="mb-0" style={{ textDecorationLine: 'line-through', textDecorationStyle: 'solid' }}>
                                {'₹' + props.productPrice}
                              </p>
                            </>
                            :
                            <h6 className="mb-0">
                              {'₹' + props.productPrice}
                            </h6>

                        }
                      </div>
                }
                {/* {props.productRating && (
              <div className="d-flex align-items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon-24"
                  width="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.1043 4.17701L14.9317 7.82776C15.1108 8.18616 15.4565 8.43467 15.8573 8.49218L19.9453 9.08062C20.9554 9.22644 21.3573 10.4505 20.6263 11.1519L17.6702 13.9924C17.3797 14.2718 17.2474 14.6733 17.3162 15.0676L18.0138 19.0778C18.1856 20.0698 17.1298 20.8267 16.227 20.3574L12.5732 18.4627C12.215 18.2768 11.786 18.2768 11.4268 18.4627L7.773 20.3574C6.87023 20.8267 5.81439 20.0698 5.98724 19.0778L6.68385 15.0676C6.75257 14.6733 6.62033 14.2718 6.32982 13.9924L3.37368 11.1519C2.64272 10.4505 3.04464 9.22644 4.05466 9.08062L8.14265 8.49218C8.54354 8.43467 8.89028 8.18616 9.06937 7.82776L10.8957 4.17701C11.3477 3.27433 12.6523 3.27433 13.1043 4.17701Z"
                    fill="#FFD329"
                  />
                </svg>
                <h6 className="mb-0">{props.productRating}</h6>
              </div>
            )} */}
              </div>
            </Card.Body>
          </span>
        </div>
      </Link>
    </Fragment>
  );
});

FeatureEventCard.displayName = "FeatureEventCard";
export default FeatureEventCard;
