import React, { memo, Fragment, useRef, useState, useEffect } from "react";

//react-bootstrap
import { Row, Col, Card, Modal, Button, Form } from "react-bootstrap";

import $ from "jquery";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { useMyContext } from "../../../../Context/MyContextProvider";
import useDataTable from "../../../../components/hooks/useDatatable";


const Roles = memo(() => {
    const { api, formatDateTime, successAlert,authToken } = useMyContext();
    const navigate = useNavigate();
    const [roles, setRoles] = useState([]);


    //role
    const RoleData = async () => {
        try {
            const response = await axios.get(`${api}role-list`, {
                headers: {
                  'Authorization': 'Bearer ' + authToken,
                }
              });
            const data = (response.data.role).reverse();
            setRoles(data);
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        RoleData();
    }, [])


    //model
    const [name, setName] = useState('');
    const [open, setOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [editId, setIsId] = useState('');
    const showModal = () => {
        setOpen(true);
    };
    const handleOk = async () => {
        try {
            const response = await axios.post(`${api}create-role`, { name, guard_name: name }, {
                headers: {
                  'Authorization': 'Bearer ' + authToken,
                }
              });
            if (response.data.status) {
                RoleData();
                handleCancel()
            }
        } catch (error) {
            handleCancel()
            console.log(error);
        }

    };
    const handleCancel = () => {
        setName("");
        setOpen(false);
        setIsEdit(false);
    };

    const HandleEdit = (id) => {
        let data = roles?.find((item)=>item?.id === id)
        setName(data?.name)
        setIsId(id)
        setIsEdit(true);
        setOpen(true);
    }
    const UpdateRole = async () => {
        try {
            const response = await axios.post(`${api}role-update`, { id: editId, name }, {
                headers: {
                  'Authorization': 'Bearer ' + authToken,
                }
              });
            if (response.data.status) {
                RoleData();
                handleCancel()
            }
        } catch (error) {
            handleCancel()
            console.log(error);
        }

    };


    const listtableRef = useRef(null);
    const columns = useRef([
        // { data: "id", title: "OrderId" },
        { data: "name", title: "Name" },
        {
            data: "created_at",
            title: "Created At",
            render: function (data) {
                return formatDateTime(data);
            },
        },
        {
            data: null,
            orderable: false,
            searchable: false,
            title: "Action",
            render: function (data) {
                return `<div class="flex align-items-center list-user-action">
                            <button class="btn btn-sm btn-icon btn-primary" data-bs-toggle="tooltip" data-bs-placement="top" title="Edit" data-id=${data?.id} data-method="Edit" data-table="action">
                                <svg class="icon-20" width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.4925 2.78906H7.75349C4.67849 2.78906 2.75049 4.96606 2.75049 8.04806V16.3621C2.75049 19.4441 4.66949 21.6211 7.75349 21.6211H16.5775C19.6625 21.6211 21.5815 19.4441 21.5815 16.3621V12.3341" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M8.82812 10.921L16.3011 3.44799C17.2321 2.51799 18.7411 2.51799 19.6721 3.44799L20.8891 4.66499C21.8201 5.59599 21.8201 7.10599 20.8891 8.03599L13.3801 15.545C12.9731 15.952 12.4211 16.181 11.8451 16.181H8.09912L8.19312 12.401C8.20712 11.845 8.43412 11.315 8.82812 10.921Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                    <path d="M15.1655 4.60254L19.7315 9.16854" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                </svg>
                            </button>
                            <button class="btn btn-sm btn-icon btn-secondary" data-bs-toggle="tooltip" data-bs-placement="top" title="Permission" data-id=${data?.id} data-method="Permission" data-table="action">
                               <svg fill="none" xmlns="http://www.w3.org/2000/svg" class="icon-32" width="32" height="32" viewBox="0 0 24 24"><path fillRule="evenodd" clipRule="evenodd" d="M11.9846 21.606C11.9846 21.606 19.6566 19.283 19.6566 12.879C19.6566 6.474 19.9346 5.974 19.3196 5.358C18.7036 4.742 12.9906 2.75 11.9846 2.75C10.9786 2.75 5.26557 4.742 4.65057 5.358C4.03457 5.974 4.31257 6.474 4.31257 12.879C4.31257 19.283 11.9846 21.606 11.9846 21.606Z" stroke="currentColor" strokeWidth="1.5" stroke-linecap="round" strokeLinejoin="round"></path><path d="M9.38574 11.8746L11.2777 13.7696L15.1757 9.86963" stroke="currentColor" strokeWidth="1.5" stroke-linecap="round" strokeLinejoin="round"></path></svg>
                            </button>
                          </div>
                        `;
            },
        },
    ]);
    useDataTable({
        tableRef: listtableRef,
        columns: columns.current,
        data: roles,
        actionCallback: (data) => {
            switch (data.method) {
                case 'Edit':
                    HandleEdit(data?.id);
                    break;
                case 'Permission':
                    AssignPermission(data?.id);
                    break;
                default:
                    break;
            }
        }
    });

    if ($.fn.DataTable.isDataTable("#datatable-ecom")) {
        $("#datatable-ecom").DataTable().destroy();
    }
    $("#datatable-ecom").DataTable({
        createdRow: function (row, data, dataIndex) {
            $(row).find("td:eq(1), td:eq(3)").css("text-align", "center");
        },
    });

    //   the function 
    const AssignPermission = async (id) => {
        navigate('assign-permission/' + id)
    }
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            isEdit ? UpdateRole() : handleOk();
        }
    };
    return (
        <Fragment>
            {/* print model  */}
            <Modal show={open} onHide={handleCancel}>
                <Modal.Header closeButton>
                    <Modal.Title>{isEdit ? 'Edit' : 'Create New'} Role</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                        <Form.Group controlId="formRoleName">
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                type="text"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                placeholder="Role name"
                                onKeyDown={handleKeyDown}
                            />
                        </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCancel}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={isEdit ? UpdateRole : handleOk}>
                        {isEdit ? 'Update Role' : 'Create Role'}
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* print model end */}
            <Row>
                <Col sm="12">
                    <Card>
                        <Card.Header className="d-flex justify-content-between">
                            <div className="header-title">
                                <h4 className="card-title">Role</h4>
                            </div>
                            <div className="button">
                                <h4 className="card-title">
                                    <Link onClick={() => showModal()}>
                                        <Button className="me-4 hvr-icon-sink-away hvr-curl-top-right border-0 d-flex align-content-center justify-content-center">
                                            New Role
                                            <svg
                                                className="size-28 hvr-icon ms-2"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    clipRule="evenodd"
                                                    d="M7.33 2H16.66C20.06 2 22 3.92 22 7.33V16.67C22 20.06 20.07 22 16.67 22H7.33C3.92 22 2 20.06 2 16.67V7.33C2 3.92 3.92 2 7.33 2ZM12.82 12.83H15.66C16.12 12.82 16.49 12.45 16.49 11.99C16.49 11.53 16.12 11.16 15.66 11.16H12.82V8.34C12.82 7.88 12.45 7.51 11.99 7.51C11.53 7.51 11.16 7.88 11.16 8.34V11.16H8.33C8.11 11.16 7.9 11.25 7.74 11.4C7.59 11.56 7.5 11.769 7.5 11.99C7.5 12.45 7.87 12.82 8.33 12.83H11.16V15.66C11.16 16.12 11.53 16.49 11.99 16.49C12.45 16.49 12.82 16.12 12.82 15.66V12.83Z"
                                                    fill="currentColor"
                                                ></path>
                                            </svg>
                                        </Button>
                                    </Link>
                                </h4>
                            </div>
                        </Card.Header>
                        <Card.Body className="px-0">
                            <div className="table-responsive">
                                <table
                                    id="datatable-ecom"
                                    ref={listtableRef}
                                    className="data-tables table custom-table movie_table"
                                />
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Fragment>
    );
});

Roles.displayName = "Roles";
export default Roles;
