import React, { memo, Fragment, useRef, useState, useEffect } from "react";

//react-bootstrap
import { Row, Col, Card, Modal, Button } from "react-bootstrap";

import $ from "jquery";
import useDataTable from "../../../../components/hooks/useDatatable";

import axios from "axios";

import Swal from "sweetalert2";
import { useReactToPrint } from 'react-to-print';
import { useMyContext } from "../../../../Context/MyContextProvider";
import { QRCodeSVG } from "qrcode.react";
const PosBooking = memo(() => {
    const { api, UserData, formatDateTime,authToken,formatDateRange ,truncateString} = useMyContext();
    const [bookings, setBookings] = useState([])
    const [bookingData, setBookingData] = useState([])
    const GetBookings = async () => {
        await axios.get(`${api}pos-bookings/${UserData?.id}`, {
            headers: {
              'Authorization': 'Bearer ' + authToken,
            }
          })
            .then((res) => {
                if (res.data.status) {
                    setBookings(res.data.bookings)
                }
            }).catch((err) =>
                console.log(err)
            )
    }

    useEffect(() => {
        GetBookings()
    }, [])


    const listtableRef = useRef(null);
    const columns = useRef([
        {
            data: null, // No direct data mapping
            orderable: false,
            title: "#",
            render: (data, type, row, meta) => meta.row + 1 // Use index + 1 as OrderId
        },
        { data: "ticket.event.name", title: "Event" },
        {
            data: "ticket.event.date_range",
            title: "Event Dates",
            render: function (data) {
                return formatDateRange(data);
            },
        },
        { data: "user_name", title: "POS User" },
        { data: "reporting_user_name", title: "Organizer" },
        
        { data: "ticket.name", title: "Ticket" },
        { data: "quantity", title: "Quantity" },
        {
            data: "discount",
            title: "Discount",
            render: function (data) {
                return `<p class="text-danger">${data}</p>`;
            },
        },
        { data: "amount", title: "Amount" },
        {
            data: "status",
            title: "Status",
            render: function (data) {
                return `<span class="badge p-1 bg-${data === "0" ? 'warning' : 'success'}">${data === "0" ? 'Uncheck' : 'Checked'}</span>`;
            },
        },
        {
            data: null,
            orderable: false,
            searchable: false,
            title: "Action",
            render: function (data) {
                const isDisabled = (data?.is_deleted === true || data?.status === "1") ? 'disabled' : '';
                return `<div class="flex align-items-center list-user-action">
                            <button class="btn btn-sm btn-icon btn-success" data-bs-toggle="tooltip" data-bs-placement="top" title="Print" data-id=${data?.id} data-method="Print" data-table="action" ${isDisabled}>
                                <i class="fa-solid fa-print"></i>
                            </button>
                        </div>`;
            },
        },
        {
            data: "created_at",
            title: "Purchase Date",
            render: function (data) {
                return formatDateTime(data);
            },
        },
        {
            data: null,
            orderable: false,
            searchable: false,

            title: "Disable",
            render: function (data) {
                const Checked = data?.is_deleted === true && "checked";
                return `<div class="flex align-items-center list-user-action">
                            <div class="form-check form-switch">
                                <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" data-table="action" data-id=${data?.id} data-method="disable" ${Checked}>
                            </div>
                        </div>`;
            },
        },
        { data: "name", title: "Customer" },
        { data: "number", title: "Contact" },
    ]);
    useDataTable({
        tableRef: listtableRef,
        columns: columns.current,
        data: bookings,
        actionCallback: (data) => {
            switch (data.method) {
                case 'Print':
                    fetchBooking(data?.id);
                    break;
                case 'edit':
                    console.log("hello");
                    break;
                case 'disable':
                    DeleteBooking(data?.id);
                    break;
                default:
                    break;
            }
        }
    });

    if ($.fn.DataTable.isDataTable("#datatable-ecom")) {
        $("#datatable-ecom").DataTable().destroy();
    }
    $("#datatable-ecom").DataTable({
        createdRow: function (row, data, dataIndex) {
            $(row).find("td:eq(1), td:eq(3)").css("text-align", "center");
        },
    });

    //   the function 
    const fetchBooking = (id) => {
        let data = bookings?.find((item) => item?.id === id)
        setBookingData(data)
        setShowPrintModel(true)
    }

    const DeleteBooking = async (id) => {
        let data = bookings?.find((item) => item?.id === id)
        if (data?.is_deleted === true) {
            await axios.get(`${api}restore-pos-booking/${id}`, {
                headers: {
                  'Authorization': 'Bearer ' + authToken,
                }
              })
                .then((res) => {
                    if (res.data.status) {
                        GetBookings()
                        Swal.fire({
                            icon: "success",
                            title: "Ticket Enabled!",
                            text: "Ticket enabled succesfully.",
                        });
                    }
                }).catch((err) =>
                    console.log(err)
                )
        } else {
            await axios.delete(`${api}delete-pos-booking/${id}`)
                .then((res) => {
                    if (res.data.status) {
                        GetBookings()
                        Swal.fire({
                            icon: "success",
                            title: "Ticket Disabled!",
                            text: "Ticket disabled succesfully.",
                        });
                    }
                }).catch((err) =>
                    console.log(err)
                )
        }
    }


    //model states
    // this page 
    const [showPrintModel, setShowPrintModel] = useState(false);
    const printRef = useRef();

    const handlePrint = useReactToPrint({
        content: () => printRef.current,
    });

    return (
        <Fragment>
            {/* print model  */}
            <Modal show={showPrintModel} onHide={() => setShowPrintModel(false)} size="sm">
                <Modal.Header closeButton>
                    <Modal.Title>Invoice</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div ref={printRef} style={{ marginBottom: '20mm' }}>
                        <div style={{ textAlign: 'center', color: "black", fontWeight: 'bold' }}>
                            <h4>{truncateString(bookingData?.ticket?.event?.name,9)}</h4>
                            <div className='qr'>
                                <QRCodeSVG
                                    size={150}
                                    style={{ height: "auto" }}
                                    className=''
                                    value={bookingData?.token}
                                />
                            </div>
                            <span>{bookingData?.id}</span>
                            <p>
                                {formatDateTime(bookingData?.created_at)}
                            </p>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th className="text-black">Qty</th>
                                        <th className="text-black">Ticket Name</th>
                                        <th className="text-black">Price</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="text-black">{bookingData?.quantity}</td>
                                        <td className="text-black">{bookingData?.ticket?.name}</td>
                                        <td className="text-black">{bookingData?.ticket?.price * bookingData?.quantity}</td>
                                    </tr>
                                </tbody>

                            </table>
                            <hr />
                            <table className="table">
                                <tbody>
                                    <tr>
                                        <td className="text-black p-0 text-end">SUBTOTAL</td>
                                        <td className="text-black py-0 text-end">₹{bookingData?.ticket?.price * bookingData?.quantity}</td>
                                    </tr>
                                </tbody>
                                <tbody>
                                    <tr>
                                        <td className="text-black p-0 text-end">TOTAL TAX</td>
                                        <td className="text-black py-0 text-end">
                                            ₹{Math.max(0, ((bookingData?.amount - -bookingData?.discount) - (bookingData?.ticket?.price * bookingData?.quantity))).toFixed(2)}
                                        </td>


                                    </tr>
                                </tbody>
                                <tbody>
                                    <tr>
                                        <td className="text-black p-0 text-end">DISCOUNT</td>
                                        <td className="text-black py-0 text-end">₹{bookingData?.discount}</td>
                                    </tr>
                                </tbody>
                                <tbody>
                                    <tr>

                                        <td className="text-black text-end pe-0">TOTAL</td>
                                        <td className="text-black text-end">₹{bookingData?.amount}</td>
                                    </tr>
                                    <tr>

                                        <td className="text-black text-end pe-0">Event Date</td>
                                        <td className="text-black text-end">₹{formatDateRange(bookingData?.ticket?.event?.date_range)}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <p style={{ fontSize: '14px' }}>
                                Thank You for Payment Type<br />
                                Please Come Again
                            </p>
                            <p style={{ fontSize: '14px' }}>
                                Powered by - GetYourTicket.co.in
                            </p>
                            <hr />
                            <p>- - - - - - - - - -</p>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowPrintModel(false)}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={() => handlePrint()}>
                        Print Invoice
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* print model end */}
            <Row>
                <Col sm="12">
                    <Card>
                        <Card.Header className="d-flex justify-content-between">
                            <div className="header-title">
                                <h4 className="card-title">Bookings</h4>
                            </div>
                        </Card.Header>
                        <Card.Body className="px-0">
                            <div className="table-responsive">
                                <table
                                    id="datatable-ecom"
                                    ref={listtableRef}
                                    className="data-tables table custom-table movie_table"
                                ></table>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Fragment>
    );
});

PosBooking.displayName = "PosBooking";
export default PosBooking;
