import React, { useEffect, useState } from 'react'
import { Card, Col, Row, Form, Button, Image } from 'react-bootstrap'
import { useMyContext } from '../../../../Context/MyContextProvider'
import axios from 'axios'
import JoditEditor from 'jodit-react'
const AdminSetting = () => {
    const { api, successAlert, authToken, ErrorAlert } = useMyContext();
    const [appName, setAppName] = useState('');
    const [metaTitle, setMetaTitle] = useState('');
    const [metaTag, setMetaTag] = useState('');
    const [metaDescription, setMetaDescription] = useState('');
    const [logo, setLogo] = useState('');
    const [favicon, setFavicon] = useState('');
    const [copyright, setCopyright] = useState('');
    const [copyrightLink, setCopyrightLink] = useState('');

    const [footerLogo, setFooterLogo] = useState('');
    const [footerAddress, setFooterAddress] = useState('');
    const [footerContact, setFooterContact] = useState('');
    const [siteCredit, setSiteCredit] = useState('');


    const GetMailConfig = async () => {
        try {
            const res = await axios.get(`${api}settings`, {
                headers: {
                    'Authorization': 'Bearer ' + authToken,
                }
            });
            if (res.data.status) {
                const configData = res.data.data;
                setAppName(configData?.app_name || '');
                setMetaTitle(configData?.meta_title || '');
                setMetaTag(configData?.meta_tag || '');
                setMetaDescription(configData?.meta_description || '');
                setLogo(configData?.logo || '');
                setFavicon(configData?.favicon || '');
                setCopyright(configData?.copyright || '');
                setCopyrightLink(configData?.copyright_link || '');

                // Set new states
                setFooterLogo(configData?.footer_logo || '');      // Footer logo
                setFooterAddress(configData?.footer_address || '');  // Footer address
                setFooterContact(configData?.footer_contact || '');
                setSiteCredit(configData?.site_credit || '');       // Site credit
            }
        } catch (err) {
            console.log(err);
        }
    };
    useEffect(() => {
        GetMailConfig()
    }, []);

    const changeFavicon = (newFaviconUrl) => {
        const favicon = document.querySelector('link[rel="icon"]');
        if (favicon) {
            favicon.href = newFaviconUrl;
        } else {
            // Create a new link element for the favicon
            const newFavicon = document.createElement('link');
            newFavicon.rel = 'icon';
            newFavicon.href = newFaviconUrl;
            document.head.appendChild(newFavicon);
        }
    }

    const handleAppConfig = async (e) => {
        try {
            e.preventDefault()
            console.log(logo)
            const formData = new FormData();
            formData.append('app_name', appName);
            formData.append('meta_title', metaTitle);
            formData.append('meta_tag', metaTag);
            formData.append('meta_description', metaDescription);
            formData.append('copyright', copyright);
            formData.append('copyright_link', copyrightLink);
            formData.append('logo', logo);
            formData.append('favicon', favicon);

            formData.append('footer_logo', footerLogo);  // Footer logo (file)
            formData.append('footer_address', footerAddress);  // Footer address (text)
            formData.append('footer_contact', footerContact);  // Footer contact (text)
            formData.append('site_credit', siteCredit);  // Site credit (text)
            const res = await axios.post(`${api}setting`, formData, {
                headers: {
                    'Authorization': 'Bearer ' + authToken,
                    'Content-Type': 'multipart/form-data'
                }
            });
            //
            if (res.data.status) {
                successAlert('Success', 'App Configuration Stored Successfully');
            }
        } catch (err) {
            console.log(err);
        }
    };


    return (
        <Row>
            <Col md={12}>
                <Card>
                    <Card.Header>
                        <h4 className="card-title">Admin Settings</h4>
                    </Card.Header>
                    <Card.Body>
                        <Form>
                            <Row>
                                <Col lg="4">
                                    <Form.Group className="mb-3 form-group">
                                        <Form.Label>Logo</Form.Label>
                                        <Form.Control type="file" id="logoFile" onChange={(e) => setLogo(e.target.files[0])} />
                                    </Form.Group>
                                </Col>
                                <Col lg="4">
                                    <Form.Group className="mb-3 form-group">
                                        <Form.Label>Favicon</Form.Label>
                                        <Form.Control type="file" id="faviconFile" onChange={(e) => setFavicon(e.target.files[0])} />
                                    </Form.Group>
                                </Col>
                                <Col lg="4">
                                    <Form.Group className="mb-3 form-group">
                                        <Form.Label>App Name</Form.Label>
                                        <Form.Control type="text" placeholder="App name" value={appName} onChange={(e) => setAppName(e.target.value)} />
                                    </Form.Group>
                                </Col>

                                {/* -----------------Footer------------------ */}
                                <hr className="hr-horizontal" />
                                <Col lg="12">
                                    <h5>Footer Detail</h5>
                                </Col>
                                {/* Footer Logo */}
                                <Col lg="4">
                                    <Form.Group className="mb-3 form-group">
                                        <Form.Label>Footer Logo</Form.Label>
                                        <Form.Control
                                            type="file"
                                            id="footerLogoFile"
                                            accept="image/*"
                                            onChange={(e) => setFooterLogo(e.target.files[0])}
                                        />
                                    </Form.Group>
                                </Col>

                                {/* Footer Address */}
                                <Col lg="4">
                                    <Form.Group className="mb-3 form-group">
                                        <Form.Label>Footer Address</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter footer address"
                                            value={footerAddress}
                                            onChange={(e) => setFooterAddress(e.target.value)}
                                        />
                                    </Form.Group>
                                </Col>

                                {/* Footer Contact */}
                                <Col lg="4">
                                    <Form.Group className="mb-3 form-group">
                                        <Form.Label>Footer Contact</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter footer contact"
                                            value={footerContact}
                                            onChange={(e) => setFooterContact(e.target.value)}
                                        />
                                    </Form.Group>
                                </Col>



                                {/* Site Credit */}
                                <Col lg="12">
                                    <Form.Group className="mb-3 form-group">
                                        <Form.Label>Site Credit</Form.Label>
                                        <JoditEditor
                                            tabIndex={1}
                                            value={siteCredit}
                                            onChange={(newContent) => setSiteCredit(newContent)}
                                        />
                                    </Form.Group>
                                </Col>
                                {/* -----------------SEO------------------ */}
                                <hr className="hr-horizontal" />
                                <Col lg="12">
                                    <h5>SEO</h5>
                                </Col>
                                <Col lg="6">
                                    <Form.Group className="mb-3 form-group">
                                        <Form.Label>Meta Title</Form.Label>
                                        <Form.Control type="text" placeholder="" value={metaTitle} onChange={(e) => setMetaTitle(e.target.value)} />
                                    </Form.Group>
                                </Col>
                                <Col lg="6">
                                    <Form.Group className="mb-3 form-group">
                                        <Form.Label>Meta Tag</Form.Label>
                                        <Form.Control type="text" placeholder="" value={metaTag} onChange={(e) => setMetaTag(e.target.value)} />
                                    </Form.Group>
                                </Col>
                                {/* -----------------Meta------------------ */}
                                <Col lg="12">
                                    <Form.Group className="mb-3 form-group">
                                        <Form.Label>Meta Description</Form.Label>
                                        <Form.Control as="textarea" placeholder="" value={metaDescription} onChange={(e) => setMetaDescription(e.target.value)} />
                                    </Form.Group>
                                </Col>
                                {/* ---------------------Copyright---------------------- */}
                                <hr className="hr-horizontal" />
                                <Col lg="12">
                                    <h5>Copyright</h5>
                                </Col>
                                <Col lg="6">
                                    <Form.Group className="mb-3 form-group">
                                        <Form.Label>Copyright Text</Form.Label>
                                        <Form.Control type="text" placeholder="" value={copyright} onChange={(e) => setCopyright(e.target.value)} />
                                    </Form.Group>
                                </Col>
                                <Col lg="6">
                                    <Form.Group className="mb-3 form-group">
                                        <Form.Label>Copyright Url</Form.Label>
                                        <Form.Control type="text" placeholder="" value={copyrightLink} onChange={(e) => setCopyrightLink(e.target.value)} />
                                    </Form.Group>
                                </Col>
                                {/* ----------------------------------- */}
                                <div className='d-flex justify-content-end'>
                                    <Button type="button" onClick={(e) => handleAppConfig(e)}>Submit</Button>
                                </div>
                            </Row>
                        </Form>
                    </Card.Body>
                </Card>
            </Col>

        </Row>
    )
}

export default AdminSetting