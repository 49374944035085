import React, { useEffect, useRef, useState } from 'react';
import { fabric } from 'fabric-pure-browser';
import { QRCodeCanvas } from 'qrcode.react';
import { Button } from 'react-bootstrap';

const TicketCanvas = ({ ticketName, ticketBG, eventDate, eventTime, eventVenue, OrderId }) => {
  const canvasRef = useRef(null);
  const qrCodeRef = useRef(null);

  useEffect(() => {
    const canvas = new fabric.Canvas(canvasRef.current);

    // Function to handle background image loading
    const loadBackgroundImage = (url) => {
      return new Promise((resolve, reject) => {
        fabric.Image.fromURL(url, (img) => {
          if (img) {
            resolve(img);
          } else {
            reject(new Error('Failed to load image'));
          }
        }, { crossOrigin: 'anonymous' });
      });
    };

    loadBackgroundImage(ticketBG)
      .then((img) => {
        const imgWidth = img.width;
        const imgHeight = img.height;

        // Set the canvas size to match the image size
        canvas.setDimensions({ width: imgWidth, height: imgHeight });

        // Set the background image
        img.scaleToWidth(imgWidth);
        img.scaleToHeight(imgHeight);
        canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas), {
          crossOrigin: 'anonymous',
        });

        // Add QR code
        const qrCodeCanvas = qrCodeRef.current;
        if (qrCodeCanvas) {
          const qrCodeDataURL = qrCodeCanvas.toDataURL('image/png');

          fabric.Image.fromURL(qrCodeDataURL, (qrImg) => {
            const qrCodeWidth = 100; // Set desired QR code size
            const qrCodeHeight = 100; // Set desired QR code size
            const padding = 5; // Set padding size
            const qrPositionX = 80; // Adjusted position for QR code
            const qrPositionY = 80; // Adjusted position for QR code

            // Create a white rectangle for padding
            const qrBackground = new fabric.Rect({
              left: qrPositionX - padding,
              top: qrPositionY - padding,
              width: qrCodeWidth + padding * 2,
              height: qrCodeHeight + padding * 2,
              fill: 'white',
              selectable: false,
            });

            qrImg.set({
              left: qrPositionX,
              top: qrPositionY,
              selectable: false,
              scaleX: qrCodeWidth / qrImg.width,
              scaleY: qrCodeHeight / qrImg.height,
            });

            // Add the rectangle and the QR code image to the canvas
            canvas.add(qrBackground, qrImg);
            canvas.renderAll();
          });
        }
      })
      .catch((error) => {
        console.error('Error loading background image:', error);
      });

    return () => {
      canvas.dispose(); // Cleanup on unmount
    };
  }, [ticketBG, ticketName, eventDate, eventTime, eventVenue, OrderId]);

  // Function to download the canvas as an image
  const [loading, setLoading] = useState(false);
  const downloadCanvas = () => {
    setLoading(true)
    const canvas = canvasRef.current;
    try {
      const link = document.createElement('a');
      link.href = canvas.toDataURL('image/png'); // Convert to data URL
      link.download = 'ticket.png'; // Set download file name
      link.click(); // Trigger the download
    } catch (error) {
      console.error('Error downloading canvas:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
        <Button
          variant="primary"
          style={{width: '50%'}}
          className="d-flex align-align-items-center justify-content-center gap-3 mb-2"
          onClick={() => downloadCanvas()}
          disabled={loading}
        >
          <span className="p-0 m-0">
            {loading ? 'Please Wait...' : 'Download'}
          </span>
          <div>
            <svg width="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="icon-24" height="32"><path opacity="0.4" d="M2 7.916V16.084C2 19.623 4.276 22 7.665 22H16.335C19.724 22 22 19.623 22 16.084V7.916C22 4.378 19.723 2 16.334 2H7.665C4.276 2 2 4.378 2 7.916Z" fill="currentColor"></path><path d="M7.72033 12.8555L11.4683 16.6205C11.7503 16.9035 12.2493 16.9035 12.5323 16.6205L16.2803 12.8555C16.5723 12.5615 16.5713 12.0865 16.2773 11.7945C15.9833 11.5025 15.5093 11.5025 15.2163 11.7965L12.7493 14.2735V7.91846C12.7493 7.50346 12.4133 7.16846 11.9993 7.16846C11.5853 7.16846 11.2493 7.50346 11.2493 7.91846V14.2735L8.78333 11.7965C8.63633 11.6495 8.44433 11.5765 8.25133 11.5765C8.06033 11.5765 7.86833 11.6495 7.72233 11.7945C7.42933 12.0865 7.42833 12.5615 7.72033 12.8555Z" fill="currentColor"></path></svg>
          </div>

        </Button>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
        <canvas ref={canvasRef} />
      </div>
      <div style={{ display: 'none' }}>
        <QRCodeCanvas ref={qrCodeRef} value={OrderId} size={150} />
      </div>
    </>
  );
};

export default TicketCanvas;
