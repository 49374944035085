import React, { useState, memo, useEffect } from 'react'
import { Button } from 'react-bootstrap'

const CustomCounter = memo((props) => {

    const { getTicketCount, category, price, limit, ticketID, disabled, selectedTickets } = props
    const [counter, setCount] = useState(0);

    useEffect(() => {
        if (selectedTickets?.id === ticketID) {
            setCount(selectedTickets?.quantity || 0);
        } else {
            setCount(0);
        }
    }, [selectedTickets, ticketID]);

    const increase = () => {
        setCount(prevCount => {
            const newCount = prevCount < limit ? prevCount + 1 : prevCount;
            getTicketCount(newCount, category, price, ticketID);
            return newCount;
        });
    };

    const decrease = () => {
        setCount(prevCount => {
            const newCount = prevCount > 0 ? prevCount - 1 : 0;
            getTicketCount(newCount, category, price, ticketID);
            return newCount;
        });
    };

    return (
        <div className=''>
            <div className={`btn-group iq-qty-btn  ${props.class}`} data-qty="btn" role="group">
                <Button variant="outline-light iq-quantity-minus" size="sm" onClick={decrease} disabled={disabled}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="3" viewBox="0 0 6 3" fill="none">
                        <path d="M5.22727 0.886364H0.136364V2.13636H5.22727V0.886364Z" fill="currentColor"></path>
                    </svg>
                </Button>
                <input type="text" data-qty="input" className="btn btn-sm btn-outline-light input-display text-black" readOnly value={counter} title="Qty" placeholder="" />
                <Button variant="outline-light iq-quantity-plus" size="sm" onClick={increase} disabled={disabled}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="9" height="8" viewBox="0 0 9 8" fill="none">
                        <path d="M3.63636 7.70455H4.90909V4.59091H8.02273V3.31818H4.90909V0.204545H3.63636V3.31818H0.522727V4.59091H3.63636V7.70455Z" fill="currentColor"></path>
                    </svg>
                </Button>
            </div>
        </div>
    )
})

CustomCounter.displayName = "CustomCounter"
export default CustomCounter