import React, { useState } from 'react'
import { Button, Card, Dropdown, Image, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import partyImage from "../../../../../assets/modules/e-commerce/images/product/party3.jpg";

import { useMyContext } from '../../../../../Context/MyContextProvider';
import TicketModal from '../../TicketModal/TicketModal';
const BookingList = ({ bookings }) => {
    const { isMobile, formatDateRange } = useMyContext()
    const [ticketData, setTicketData] = useState([]);
    const [ticketType, setTicketType] = useState({ id: '', type: '' });
    const [show, setShow] = useState(false);


    const formatDate = (isoDate) => {
        const date = new Date(isoDate);
        // Format the date
        const dateOptions = { year: 'numeric', month: 'short', day: 'numeric' };
        const formattedDate = date.toLocaleDateString('en-US', dateOptions);
        // Format the time
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const period = hours >= 12 ? 'PM' : 'AM';
        const hours12 = hours % 12 || 12; // Convert hour to 12-hour format, with 12 instead of 0
        const minutesFormatted = minutes.toString().padStart(2, '0'); // Format minutes with leading zero
        const formattedTime = `${hours12}:${minutesFormatted} ${period}`;
        return `${formattedDate} ${formattedTime}`;
    };

    const handleTicketPreview = (item, type, id) => {
        setTicketData(item)
        setTicketType({ id: id, type: type })
        setShow(true)
    }


    const generateRandomString = (length = 4) => {
        // Define the characters to use in the random string
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
        let result = '';
        // Generate the random string
        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            result += characters[randomIndex];
        }
        return result;
    };
    function handleCloseModal() {
        setTicketData([])
        setTicketType()
        setShow(false)
    }
    return (
        <>
            {/* print model  */}
            <TicketModal
                show={show}
                handleCloseModal={handleCloseModal}
                ticketType={ticketType}
                ticketData={ticketData}
                formatDateRange={formatDateRange}
            />
            {/* print model end */}
            {
                bookings?.length > 0 &&
                bookings?.map((item, index) => {
                    return (
                        <Card className="card shadow-none border iq-product-order-placed" key={index} style={{ marginBottom: isMobile && '3rem' }}>
                            <div className="card-header user-details-bg-color bg-light px-4 py-2">
                                <div className="iq-order-content">
                                    <div className="iq-order-user-details d-flex justify-content-between align-items-center gap-4">
                                        <div>
                                            <p className='d-flex align-items-center gap-2'>Date : <h6 className="mb-0">{formatDate(item?.created_at)}</h6></p>

                                        </div>
                                        <div>
                                            <p className='d-flex align-items-center gap-2'>Qty :
                                                <h6 className="mb-0">{item?.bookings?.length > 1 ? item?.bookings?.length : 1}</h6>
                                            </p>
                                        </div>
                                        {/* <div>
                                    <p>Ship to</p>
                                    <h6 className="mb-0 text-primary">Elon Musk</h6>
                                </div> */}
                                    </div>
                                </div>
                            </div>
                            <Card.Body>
                                <div className="d-flex align-items-center justify-content-between flex-wrap">
                                    <div className="d-flex align-items-center">
                                        <Image
                                            src={(item?.ticket?.event?.thumbnail || item?.bookings[0]?.ticket?.event?.thumbnail) || partyImage}
                                            alt="user-profile"
                                            className="img-fluid rounded  avatar-100 iq-product-bg"
                                        />
                                        <div className={`ms-3`}>
                                            <Link style={{ pointerEvents: 'none' }} to="">
                                                <h6 className="mb-2">{item?.ticket?.event?.name || (item?.bookings && item?.bookings[0]?.ticket?.event?.name)}</h6>
                                            </Link>
                                            <div className={`${!isMobile && 'd-flex flex-column gap-2'}`}>
                                                <h6 className="mb-xl-0 mb-2 iq-order-id">
                                                    Event Date: <Link style={{ pointerEvents: 'none' }} to="#">{formatDateRange(item?.ticket?.event?.date_range)}</Link>
                                                </h6>
                                                <h6 className="mb-xl-0 mb-2 iq-order-id">
                                                    Amount: <Link style={{ pointerEvents: 'none' }} to="#">
                                                        ₹{item?.base_amount - item?.discount ||
                                                            (item?.bookings?.[0]?.base_amount - (item?.bookings?.[0]?.discount || 0)) ||
                                                            0}
                                                    </Link>
                                                </h6>
                                                <h6 className="mb-xl-0 mb-2 iq-order-id">
                                                    User: <Link style={{ pointerEvents: 'none' }} to="#">{item?.name || item?.bookings?.[0]?.name}</Link>
                                                </h6>
                                                <h6 className="mb-xl-0 mb-2 iq-order-id">
                                                    Mo : <Link style={{ pointerEvents: 'none' }} to="#">{item?.number || item?.bookings?.[0]?.number}</Link>
                                                </h6>
                                                <h6 className="mb-xl-0 mb-2 iq-order-id">
                                                    Booking ID: <Link style={{ pointerEvents: 'none' }} to="#">{generateRandomString() + item?.id + generateRandomString()}</Link>
                                                </h6>
                                                <h6 className="mb-xl-0 mb-2 iq-order-id">
                                                    Status: <span className={`badge p-1 bg-${(item?.status || item?.bookings?.[0]?.status) === "0" ? "warning" : "success"}`}>
                                                        {(item?.status || item?.bookings?.[0]?.status) === "0" ? "Uncheck" : "Checked"}
                                                    </span>
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-3 mt-xl-0 mt-3 mt-md-0">
                                        <div className="text-end">
                                            <Dropdown>
                                                <Dropdown.Toggle
                                                    as={Button}
                                                    variant="btn-primary"
                                                    bsPrefix="btn-primary mb-3 "
                                                >
                                                    Generate E-Ticket{" "}
                                                    <svg
                                                        width="12"
                                                        className="ms-2"
                                                        viewBox="0 0 12 8"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            fillRule="evenodd"
                                                            clipRule="evenodd"
                                                            d="M6 5.08579L10.2929 0.792893C10.6834 0.402369 11.3166 0.402369 11.7071 0.792893C12.0976 1.18342 12.0976 1.81658 11.7071 2.20711L6.70711 7.20711C6.31658 7.59763 5.68342 7.59763 5.29289 7.20711L0.292893 2.20711C-0.0976311 1.81658 -0.0976311 1.18342 0.292893 0.792893C0.683418 0.402369 1.31658 0.402369 1.70711 0.792893L6 5.08579Z"
                                                            fill="currentColor"
                                                        />
                                                    </svg>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <li>
                                                        <Dropdown.Item
                                                            disabled={ticketType && ticketType.id === item.id}
                                                            onClick={() => handleTicketPreview(item, 'combine', item?.id)}>
                                                            Combine
                                                        </Dropdown.Item>
                                                    </li>

                                                    {item?.bookings &&
                                                        <>
                                                            <li>
                                                                <Dropdown.Divider />
                                                            </li>
                                                            <li>
                                                                <Dropdown.Item
                                                                    disabled={ticketType && ticketType.id === item.id}
                                                                    onClick={() => handleTicketPreview(item, 'individual', item?.id)}>
                                                                    Individual
                                                                </Dropdown.Item>
                                                            </li>
                                                        </>
                                                    }
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <div className="text-center d-flex">
                                            <Link to="">send tickets</Link>
                                            <span className="mx-1">|</span>
                                            <div className="d-flex gap-2 align-items-center">
                                                <OverlayTrigger
                                                    placement={'top'}
                                                    overlay={<Tooltip>Whatsapp</Tooltip>}
                                                >
                                                    <Link>
                                                        <i className="fa-brands fa-whatsapp text-success"></i>
                                                    </Link>
                                                </OverlayTrigger>
                                                <OverlayTrigger
                                                    placement={'top'}
                                                    overlay={<Tooltip>SMS</Tooltip>}
                                                >
                                                    <Link>
                                                        <i className="fa-regular fa-message text-warning"></i>
                                                    </Link>
                                                </OverlayTrigger>
                                                <OverlayTrigger
                                                    placement={'top'}
                                                    overlay={<Tooltip>Email</Tooltip>}
                                                >
                                                    <Link>
                                                        <i className="fa-regular fa-envelope text-danger"></i>
                                                    </Link>
                                                </OverlayTrigger>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card >
                    )
                })
            }
        </>
    )
}

export default BookingList