import React, { Fragment, memo} from "react";
import { Col, Container, Row } from "react-bootstrap";

//Swiper
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";


import EventsContainer from "../Events/LandingEvents/EventsContainer";
import FeatureEvent from "../Events/LandingEvents/FeatureEvent";

//category images
// import CategoryBG from '../../../../assets/event/stock/Other/baground.jpg'
import CategoryBG from '../../../../assets/event/stock/gradient.jpg'
import Category1 from '../../../../assets/event/stock/Other/1.jpg'
import Category2 from '../../../../assets/event/stock/Other/2.jpg'
import Category3 from '../../../../assets/event/stock/Other/3.jpg'
import Category4 from '../../../../assets/event/stock/Other/4.jpg'
import Category5 from '../../../../assets/event/stock/Other/5.jpg'
import Category6 from '../../../../assets/event/stock/Other/6.jpg'
import Category7 from '../../../../assets/event/stock/Other/7.jpg'
import ImageGallery from "./ImageGallery";

import cardBg from '../../../../assets/event/stock/glass_bg3.jpg'
import Banners from "./Sections/BannersSwipper";


const Home = memo((props) => {
  const categoryImages = [Category1, Category2, Category3, Category4, Category5, Category6, Category7,]



  return (
    <Fragment>
      <Banners/>
      <div className="main bg-cover" style={{ background: `url(${cardBg})` }}>
        <div className="section-padding  py-2 pt-4">
          <Container fluid className="px-5">
            <FeatureEvent />
          </Container>
        </div>
        <div className="section-padding  py-2">
          <Container fluid className="px-5">
            <div className="d-flex py-2 pt-0">
              <h5 className="text-secondary text-capitalize">Events</h5>
            </div>
            <EventsContainer />
          </Container>
        </div>
      </div>
      <div className="py-3" style={{ background: `url(${CategoryBG})`, backgroundSize: 'cover' }}>
        <Container fluid className="px-5">
          <Row className="align-items-center">
            <Col md={12} className="text-center">
              <h4 className="text-secondary pb-2 text-capitalize">
                Events{" "}
                <span className="text-primary">Category</span>
              </h4>
            </Col>
            <div
              className="overflow-hidden slider-circle-btn  "
              id="app-slider"
            >
              <Swiper
                className="p-0 m-0 mb-2 swiper-wrapper list-inline"
                slidesPerView={5}
                spaceBetween={32}
                autoplay={true}
                navigation={{
                  nextEl: "#app-slider-next",
                  prevEl: "#app-slider-prev",
                }}
                breakpoints={{
                  320: { slidesPerView: 2 },
                  550: { slidesPerView: 2 },
                  991: { slidesPerView: 4 },
                  1400: { slidesPerView: 4 },
                  1500: { slidesPerView: 6 },
                  1920: { slidesPerView: 6 },
                  2040: { slidesPerView: 6 },
                  2440: { slidesPerView: 6 },
                }}
              >
                {categoryImages?.map((item, i) => (
                  <SwiperSlide className="card card-slide overflow-hidden" key={i}>
                    <img
                      src={item}
                      alt="team-details"
                      className="img-fluid"
                      loading="lazy"
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </Row>
        </Container>
      </div>
      <div className="py-3 page-bg bg-cover" style={{ background: `url(${cardBg})` }}>
        <Container fluid className="px-5">
          <ImageGallery />
        </Container>
      </div>
    </Fragment>
  );
});

export default Home;
