import React, { memo, Fragment, useRef, useState, useEffect } from "react";

//react-bootstrap
import { Row, Col, Card, Modal, Button, ProgressBar, Spinner } from "react-bootstrap";
import $ from "jquery";


import axios from "axios";
import loader from '../../../../assets/event/stock/loder.gif';
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { useMyContext } from "../../../../Context/MyContextProvider";
import useDataTable from "../../../../components/hooks/useDatatable";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import QRGenerator from "../Events/Tickets/QRGenerator";
import { createRoot } from "react-dom/client";
import ReactDOM from 'react-dom/client';
const CbList = memo(() => {
    const { api, UserData, formatDateTime, authToken } = useMyContext();
    const [bookings, setBookings] = useState([]);
    const [bookingData, setBookingData] = useState([]);
    const [progress, setProgress] = useState(0);
    const GetBookings = async () => {
        await axios
            .get(`${api}complimentary-bookings/${UserData?.id}`, {
                headers: {
                    'Authorization': 'Bearer ' + authToken,
                }
            })
            .then((res) => {
                if (res.data.status) {
                    setBookings(res.data.data);
                }
            })
            .catch((err) => console.log(err));
    };

    useEffect(() => {
        GetBookings();
        // showLoading()
    }, []);

    const listtableRef = useRef(null);
    const columns = useRef([
        {
            data: null, // No direct data mapping
            orderable: false,
            title: "#",
            render: (data, type, row, meta) => meta.row + 1 // Use index + 1 as OrderId
        },
        { data: "name", title: "Name" },
        { data: "number", title: "Number" },
        { data: "event_name", title: "Event Name" },
        { data: "ticket_name", title: "Ticket Type" },
        { data: "booking_count", title: "Totol Bookings" },
        {
            data: null,
            orderable: false,
            searchable: false,

            title: "Disable",
            render: function (data) {
                const Checked = data?.is_deleted === true && "checked";
                return `<div class="flex align-items-center list-user-action">
                            <div class="form-check form-switch">
                                <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" data-table="action" data-id=${data?.id} data-method="disable" ${Checked}>
                            </div>
                        </div>`;
            },
        },
        {
            data: "booking_date",
            title: "Generate Date",
            render: function (data) {
                return formatDateTime(data);
            },
        },
        {
            data: null,
            orderable: false,
            searchable: false,
            title: "Action",
            render: function (data) {
                return `<div class="flex align-items-center list-user-action">
                            <button class="btn btn-sm btn-icon btn-success" data-bs-toggle="tooltip" data-bs-placement="top" title="Zip" data-id=${data?.batch_id} data-method="Zip" data-table="action">
                                <svg width="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="icon-20" height="20"><path d="M13.0801 1H6C4.89543 1 4 1.89543 4 3V21C4 22.1046 4.89543 23 6 23H18C19.1046 23 20 22.1046 20 21V8.73981M13.0801 1C13.664 1 14.2187 1.25513 14.5986 1.69841L19.5185 7.43822C19.8292 7.80071 20 8.26239 20 8.73981M13.0801 1V5.73981C13.0801 7.39666 14.4232 8.73981 16.0801 8.73981H20" stroke="currentColor"></path><path d="M9.15961 13.1986L9.15957 13.1986L9.15961 13.1986Z" stroke="currentColor" stroke-linecap="round"></path><line x1="12.975" y1="12.6181" x2="11.2497" y2="18.6566" stroke="currentColor" stroke-linecap="round"></line><path d="M15.1037 17.8012C15.1037 17.8012 15.1037 17.8013 15.1036 17.8014L15.1037 17.8013L15.1037 17.8012Z" stroke="currentColor" stroke-linecap="round"></path></svg>
                            </button>
                        </div>`;
            },
        },
    ]);
    useDataTable({
        tableRef: listtableRef,
        columns: columns.current,
        data: bookings,
        actionCallback: (data) => {
            switch (data.method) {
                case "Zip":
                    HandleModel(data?.id);
                    break;
                case "edit":
                    console.log("hello");
                    break;
                case "disable":
                    DeleteBooking(data?.id);
                    break;
                default:
                    break;
            }
        },
    });

    if ($.fn.DataTable.isDataTable("#datatable-ecom")) {
        $("#datatable-ecom").DataTable().destroy();
    }
    $("#datatable-ecom").DataTable({
        createdRow: function (row, data, dataIndex) {
            $(row).find("td:eq(1), td:eq(3)").css("text-align", "center");
        },
    });


    const DeleteBooking = async (id) => {
        let data = bookings?.find((item) => item?.id === id);
        if (data?.is_deleted === true) {
            await axios
                .get(`${api}restore-booking/${data?.token || data?.order_id}`,{
                    headers: {
                        'Authorization': 'Bearer ' + authToken,
                    }
                })
                .then((res) => {
                    if (res.data.status) {
                        GetBookings();
                        Swal.fire({
                            icon: "success",
                            title: "Ticket Enabled!",
                            text: "Ticket enabled succesfully.",
                        });
                    }
                })
                .catch((err) => console.log(err));
        } else {
            await axios
                .delete(`${api}delete-booking/${data?.token || data?.order_id}`,{
                    headers: {
                        'Authorization': 'Bearer ' + authToken,
                    }
                })
                .then((res) => {
                    if (res.data.status) {
                        GetBookings();
                        Swal.fire({
                            icon: "success",
                            title: "Ticket Disabled!",
                            text: "Ticket disabled succesfully.",
                        });
                    }
                })
                .catch((err) => console.log(err));
        }
    };

    const [showPrintModel, setShowPrintModel] = useState(false);
    const [loading, setLoading] = useState();
    const HandleModel = (data) => {
        AskAlert('Do u want to make a zip again', 'Yes Make A Zip', data)
        setBookingData(data)
    }

    const AskAlert = (title, buttonText, data) => {
        Swal.fire({
            title: "Are you sure?",
            text: title,
            icon: "warning",
            showCancelButton: true,
            backdrop: `rgba(60,60,60,0.8)`,
            confirmButtonText: buttonText,
        }).then((result) => {
            if (result.isConfirmed) {
                setLoading(true)
                fetchBookings(data)
            }
        });
    }

    // useEffect(() => {
    //     if (loading) {
    //       //  showLoading()
    //     }
    // }, [loading]);

    const fetchBookings = async (data) => {
        await axios
            .post(`${api}fetch-batch-cb/${UserData?.id}`, { batch_id: data }, {
                headers: {
                    'Authorization': 'Bearer ' + authToken,
                }
            })
            .then((res) => {
                if (res.data.status) {
                    let bookings = res.data?.tokens
                    generateZip(bookings);
                }
            })
            .catch((err) => console.log(err));
    };
    const generateZip = async (data) => {
        const loadingAlert = showLoading();
        if (data?.length > 0) {
            setLoading(true);
            try {
                const zip = new JSZip();
                const total = data?.length;
                let completed = 0;
                const qrCodePromises = data.map((data, index) =>
                    new Promise((resolve) => {
                        const qrElement = document.createElement("div");
                        qrElement.style.display = "none";
                        document.body.appendChild(qrElement);

                        const qrGenerator = <QRGenerator value={data} documentId={index} />;
                        const qrRoot = createRoot(qrElement);
                        qrRoot.render(qrGenerator);

                        setTimeout(() => {
                            const canvas = qrElement.querySelector("canvas");
                            if (canvas) {
                                canvas.toBlob((blob) => {
                                    zip.file(`qrcode_${data}.png`, blob);
                                    completed++;
                                    const progress = Math.round((completed / total) * 100);
                                    loadingAlert.update({
                                        html: `
                                            <div style="text-align: center;">
                                            <img src=${loader} style="width: 10rem; display: block; margin: 0 auto;"/>
                                                <div>Progress: <span id="progress-text">${progress}%</span></div>
                                                <div class="mt-4" style="width: 100%; border: 1px solid  #dddddd4f; border-radius: 10px;">
                                                    <div class="progress-bar bg-primary progress-bar-striped" id="progress-bar" style="width: ${progress}%; height: 10px; text-align: center; color: white; line-height: 20px; border-radius: 4px;"></div>
                                                </div>
                                            </div>
                                        `,
                                        text: `Processing... ${progress}%`
                                    });

                                    resolve();
                                });
                            }
                            document.body.removeChild(qrElement);
                        }, 1000); // Delay to ensure QR code is rendered
                    })
                );
                await Promise.all(qrCodePromises);
                zip.generateAsync({ type: "blob" }).then((content) => {
                    saveAs(content, "qr_codes.zip");
                    setLoading(false);
                });
            } catch (error) {
                console.error("Error generating ZIP:", error);
            } finally {
                setLoading(false);
                loadingAlert.close(); // Close the loading alert
                Swal.fire({
                    title: 'Finished!',
                    icon: 'success',
                    timer: 2000,
                    showConfirmButton: false
                });

            }
        }
    };
    const showLoading = (progress = 0) => {
        return Swal.fire({
            title: "Processing",
            text: progress === 0 ? "Processing will start soon. Please wait..." : "Processing...",
            html: `
                <div style="text-align: center;">
                    <img src=${loader} style="width: 10rem; display: block; margin: 0 auto;"/>
                    <div class="spinner-border text-primary mt-4" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
            `,
            allowEscapeKey: false,
            allowOutsideClick: false,
            showConfirmButton: false,
            customClass: {
                htmlContainer: 'swal2-html-container-cutom'
            },

        });
    };
    return (
        <Fragment>
            {/* <Modal show={showPrintModel} backdrop="static" keyboard={false} centered>
                <Modal.Body className="text-center">
                    <h4>Processing...</h4>
                    <Spinner animation="border" variant="primary" />
                    <ProgressBar
                        animated
                        striped
                        now={progress}
                        className="mt-3"
                        style={{ height: '6px' }}
                    />
                </Modal.Body>
            </Modal> */}
            <Row>
                <Col sm="12">
                    <Card>
                        <Card.Header className="d-flex justify-content-between">
                            <div className="header-title">
                                <h4 className="card-title">complimentary Bookings</h4>
                                <div
                                    className="progress-bar bg-primary"
                                    data-toggle="progress-bar"
                                    role="progressbar"
                                    aria-valuenow="70"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                    style={{
                                        width: "70%",
                                        transition: "width 2s ease 0s",
                                    }}
                                ></div>
                            </div>
                            <div className="button">
                                <h4 className="card-title">
                                    <Link to={"new"}>
                                        <Button className="me-4 hvr-curl-top-right border-0">
                                            New Booking
                                        </Button>
                                    </Link>
                                </h4>

                            </div>
                        </Card.Header>
                        <Card.Body className="px-0">
                            <div className="table-responsive">
                                <table
                                    id="datatable-ecom"
                                    ref={listtableRef}
                                    className="data-tables table custom-table movie_table"
                                ></table>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Fragment>
    );
});

CbList.displayName = "CbList";
export default CbList;
