import { Row } from "react-bootstrap"
import ForMobile from "./ForMobile"

const ArtistComponent = () => {
    return (
        <Row>
            <ForMobile/>
            {/* <ForMobile/> */}
        </Row>
    )
}

export default ArtistComponent