import React, { Fragment, useEffect, useState } from 'react';
import { Col, Row, Button, Card, Form, Modal } from "react-bootstrap";
import Select from "react-select";
import axios from 'axios';
import { useMyContext } from '../../../../../../Context/MyContextProvider';
import { DleteIcon, EditIcon } from '../../../CustomHooks/CustomIcon';
import AttendySugettion from './BookingProcess.js/AttendySugettion';
import { PlusIcon } from 'lucide-react';
import Swal from 'sweetalert2';
import BookingsAttendee from './BookingsAttendee';

const DynamicAttendeeForm = ({ apiData, setAttendeeState, quantity, AttendyView, setAttendees, setDisable, category_id, disable }) => {
    const { api, successAlert, UserData } = useMyContext()
    const [existingAttendee, setExistingAttendee] = useState([]);
    const [attendeeList, setAttendeesList] = useState([]);
    const [attendeeData, setAttendeeData] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [editingIndex, setEditingIndex] = useState(null);

    const [requiredFields, setRequiredFields] = useState([]);


    const handleOpenModal = (index = null) => {
        if (index !== null) {
            setAttendeeData(attendeeList[index]);
            setEditingIndex(index);
        } else {
            setAttendeeData({});  // Reset form for new attendee
            setEditingIndex(null);
        }
        setShowModal(true);
    };
    const GetUserAttandee = async () => {
        try {
            await axios.get(`${api}user-attendee/${UserData.id}/${category_id}`)
                .then((response) => {
                    const Exising_attendy = response.data.attendees;
                    if (Exising_attendy.length > 0) {
                        setExistingAttendee(Exising_attendy)
                        setShowAddAttendeeModal(true);
                    }
                })
                .catch((error) => {

                });

        } catch (error) {

        }
    }
    useEffect(() => {
        const requiredFields = apiData.filter((field) => field.field_required === 1);
        const requiredFieldNames = requiredFields.map((field) => field.field_name);
        setRequiredFields(requiredFieldNames)
    }, [apiData]);

    useEffect(() => {
        if (UserData, category_id) {
            GetUserAttandee()
        }
    }, [UserData.id, category_id])

    const handleCloseModal = () => setShowModal(false);

    const Back = () => {
        AttendyView()
        setAttendeeState(false)
        setDisable(false)
    }

    const handleFieldChange = (fieldName, value) => {
        setAttendeeData(prevData => ({
            ...prevData,
            [fieldName]: value, // Set field name as key and value as field's value
        }));
    };

    const handleAddAttendee = () => {
        if (editingIndex !== null) {
            const updatedAttendeeData = { ...attendeeData };
            console.log(attendeeData)
            // Calculate missing fields based on the current data
            let missingFields = requiredFields?.filter(field => !updatedAttendeeData[field] || updatedAttendeeData[field] === "");

            const updatedList = [...attendeeList];
            updatedList[editingIndex] = {
                ...updatedAttendeeData,
                missingFields
            };

            setAttendeesList(updatedList);
            setAttendees(updatedList);
        } else {
            // If attendeeData is empty (or user removed fields), handle missing fields again
            const missingFields = requiredFields?.filter(field => !attendeeData[field] || attendeeData[field] === "");

            // Add a new attendee, and include missing fields if necessary
            setAttendeesList([
                ...attendeeList,
                { ...attendeeData, missingFields }
            ]);
            setAttendees([
                ...attendeeList,
                { ...attendeeData, missingFields }
            ]);
        }

        // Reset attendee data and close the modal
        setAttendeeData({});
        handleCloseModal();
    };


    const handleDeleteAttendee = (index) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
        }).then((result) => {
            if (result.isConfirmed) {
                const updatedList = attendeeList.filter((_, i) => i !== index);
                setAttendeesList(updatedList);
                setAttendees(updatedList);

                Swal.fire(
                    'Deleted!',
                    'The attendee has been deleted.',
                    'success'
                );
            } else {
                Swal.fire(
                    'Cancelled',
                    'The attendee was not deleted.',
                    'info'
                );
            }
        });
    };



    const renderField = (field) => {
        const { field_name, field_type, field_options = [], required } = field;
        const value = attendeeData[field_name] || '';
        const label = required ? `${field_name} *` : field_name;

        const onChange = (e) => {
            const value = e.target ? e.target.value : e;
            if (value && typeof value === 'object' && value.label && value.value) {
                handleFieldChange(field_name, value.value);
            } else {
                if (e.target.type === 'file') {
                    const file = e.target.files[0];
                    handleFieldChange(field_name, file);
                } else {
                    handleFieldChange(field_name, value);
                }
            }
        };

        switch (field_type) {
            case 'text':
            case 'email':
                return (
                    <Form.Group>
                        <Form.Label>{label}</Form.Label>
                        <Form.Control
                            type={field_type}
                            value={value}
                            placeholder={`Enter ${field_name}`}
                            onChange={onChange}
                            required={required}
                        />
                    </Form.Group>
                );
            case 'select':
                return (
                    <Form.Group>
                        <Form.Label>{label}</Form.Label>
                        <Select
                            value={{ label: value, value }}
                            options={JSON.parse(field_options).map(option => ({ label: option, value: option }))}
                            onChange={onChange}
                            isRequired={required}
                        />
                    </Form.Group>
                );
            case 'radio':
                return (
                    <div>
                        <Form.Label>{label}</Form.Label>
                        <div className="d-flex gap-3">
                            {JSON.parse(field_options).map((option, index) => (
                                <Form.Check
                                    key={index}
                                    type="radio"
                                    checked={value === option}
                                    label={option}
                                    value={option}
                                    name={field_name}
                                    onChange={onChange}
                                    required={required}
                                />
                            ))}
                        </div>
                    </div>
                );
            case 'checkbox':
                return (
                    <div>
                        <Form.Label>{label}</Form.Label>
                        {JSON.parse(field_options).map((option, index) => (
                            <Form.Check
                                key={index}
                                type="checkbox"
                                checked={Array.isArray(value) && value?.includes(option)}
                                label={option}
                                onChange={(e) => onChange(e, option)}
                                required={required}
                            />
                        ))}
                    </div>
                );
            case 'textarea':
                return (
                    <Form.Group>
                        <Form.Label>{label}</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            value={value}
                            placeholder={`Enter ${field_name}`}
                            onChange={onChange}
                            required={required}
                        />
                    </Form.Group>
                );
            case 'number':
                return (
                    <Form.Group>
                        <Form.Label>{label}</Form.Label>
                        <Form.Control
                            type="number"
                            value={value}
                            placeholder={`Enter ${field_name}`}
                            onChange={onChange}
                            required={required}
                        />
                    </Form.Group>
                );
            case 'date':
                return (
                    <Form.Group>
                        <Form.Label>{label}</Form.Label>
                        <Form.Control
                            type="date"
                            value={value}
                            onChange={onChange}
                            required={required}
                        />
                    </Form.Group>
                );
            case 'file':
                return (
                    <Form.Group>
                        <Form.Label>{label}</Form.Label>
                        <Form.Control
                            type="file"
                            onChange={onChange}
                            required={required}
                        />
                    </Form.Group>
                );
            case 'color':
                return (
                    <Form.Group>
                        <Form.Label>{label}</Form.Label>
                        <Form.Control
                            type="color"
                            onChange={onChange}
                            required={required}
                        />
                    </Form.Group>
                );
            case 'range':
                return (
                    <Form.Group>
                        <Form.Label>{label}</Form.Label>
                        <Form.Control
                            type="range"
                            onChange={onChange}
                            required={required}
                        />
                    </Form.Group>
                );
            default:
                return null;
        }
    };

    const HandleSubmit = async () => {
        // put validation if any attendeeList data has missingFields
        if (attendeeList.some(attendee => Object.values(attendee).some(value => value === null || value === undefined || value === ''))) {
            Swal.fire('Error', 'Missing fields detected', 'error');
            return;
        }

        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, Save it!',
            cancelButtonText: 'No, cancel!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const formData = new FormData();
                    attendeeList?.forEach((attendee, index) => {
                        Object.keys(attendee).forEach(fieldKey => {
                            const fieldValue = attendee[fieldKey];
                            formData.append(`attendees[${index}][${fieldKey}]`, fieldValue);
                        });
                    });
                    // append user id in formData
                    formData.append('user_id', UserData?.id);
                    const response = await axios.post(`${api}attndy-store`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    });
                    if (response.data.status) {
                        successAlert('Success', 'Attendees Saved Successfully')
                        setAttendees(response.data.data)
                        setDisable(false)
                    }
                } catch (error) {
                    console.error('Error submitting form data:', error);
                }
            }
        });
    };




    // make state for toogle attendy suggetions modal 
    const [showAddAttendeeModal, setShowAddAttendeeModal] = useState(false);

    const renderAttendeeDetails = (attendee) => {
        return Object.keys(attendee).map((key) => {
            const value = attendee[key];

            // Check if value is an object (like File) before rendering
            if (typeof value !== 'object') {
                return (
                    <div key={key}>
                        <strong>{key}:</strong> {value}
                    </div>
                );
            }
            return null; // Skip rendering if it's an object
        });
    };
    return (
        <Col lg="8">
            <>
                <Card className="mb-4">
                    <Card.Header className="d-flex justify-content-between align-items-center">
                        <h5>Attendees {attendeeList.length + '/' + quantity}</h5>
                        <Button variant="secondary" onClick={Back}>
                            Back
                        </Button>
                    </Card.Header>

                    {/* Render Attendee Cards */}
                    <BookingsAttendee
                        attendeeList={attendeeList}
                        apiData={apiData}
                        handleOpenModal={handleOpenModal}
                        handleDeleteAttendee={handleDeleteAttendee}
                        ShowAction={true}
                    />


                    {/* Add Attendee Button */}
                    {attendeeList?.length < quantity && (
                        <Card.Footer className="d-flex justify-content-center ">
                            <Button variant="primary" className='d-flex align-items-center gap-2' onClick={() => handleOpenModal()}>
                                <PlusIcon size={20} /> Add Attendee
                            </Button>
                        </Card.Footer>
                    )}
                </Card>
                <AttendySugettion
                    quantity={quantity}
                    totalAttendee={attendeeList.length}
                    showAddAttendeeModal={showAddAttendeeModal}
                    setShowAddAttendeeModal={setShowAddAttendeeModal}
                    data={existingAttendee}
                    openAddModal={setShowModal}
                    requiredFields={requiredFields}
                    setAttendeesList={setAttendeesList}
                />
                {/* Modal for Adding Attendee */}
                <Modal show={showModal} onHide={handleCloseModal} size='xl'>
                    <Modal.Header closeButton>
                        <Modal.Title>Add Attendee Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <>
                            <Row>
                                {apiData?.map((field, fieldIndex) => (
                                    <Col md={6} key={fieldIndex} className="text-black mb-2">
                                        {renderField(field)}
                                    </Col>
                                ))}
                            </Row>
                            <Button
                                variant="primary"
                                type="submit"
                                className='position-relative float-end'
                                onClick={() => handleAddAttendee()}>
                                {editingIndex ? 'Update' : 'Save'}
                            </Button>
                        </>
                    </Modal.Body>
                </Modal>

                {/* Submit All Attendee Details */}
                {attendeeList?.length === quantity && (
                    <Card.Footer className="text-end">
                        <Button variant="primary" onClick={HandleSubmit} disabled={!disable}>
                            Save All Attendee Details
                        </Button>
                    </Card.Footer>
                )}
            </>
        </Col>
    );
};

export default DynamicAttendeeForm;
