import React from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const MobileCheckOut = ({ ticketCurrency, handlePayment, grandTotal, isAttendeeRequired, disable }) => {
    return (
        <Container
            fluid
            className={`d-flex flex-column justify-content-end`}
            style={{
                position: 'fixed',
                left: '0',
                zIndex: '99',
                bottom: '0',
                maxWidth: '100%',
                margin: '0',
                padding: '0',
            }}
            onClick={() => handlePayment()}
        >
            <Row className="g-0">
                <Col xs={6} className="p-0">
                    <Button
                        //onClick={() => handlePayment()}
                        disabled={disable}
                        variant="secondary"
                        className="w-100 text-white py-4"
                        style={{ borderRadius: '0' }}
                    >
                        Amount : <strong className="text-white h5">{ticketCurrency}{grandTotal}</strong>
                    </Button>
                </Col>
                <Col xs={6} className="p-0">
                    <Link
                       // onClick={() => handlePayment()}
                        disabled={disable}
                        to=""
                        className="btn btn-primary w-100 d-flex align-items-center justify-content-center py-4"
                        style={{ borderRadius: '0', textDecoration: 'none' }}
                    >
                        {isAttendeeRequired ? 'Next' : 'Checkout'}
                    </Link>
                </Col>
            </Row>
        </Container>

    )
}

export default MobileCheckOut