import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Modal, Row } from 'react-bootstrap'
import axios from 'axios';
import { useMyContext } from '../../../../Context/MyContextProvider';
import { CustomCheckbox } from '../CustomComponents/CustomFormFields';
const AssignFields = ({ showFields, HandleCloseFields, editState, getIDs,existingIds,setFieldsName }) => {
    const { api, successAlert, authToken } = useMyContext();
    const [fields, setFields] = useState([]);
    const [ids, setIds] = useState([])
    const GetCategories = async () => {
        try {
            const res = await axios.get(`${api}fields-name`, {
                headers: {
                    'Authorization': 'Bearer ' + authToken,
                }
            });
            if (res.data.status) {
                const data = res.data.customFields;
                setFields(data)
            }
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        GetCategories()
    }, [])
    useEffect(() => {
        if(editState){
           setIds(existingIds)
        }
    }, [existingIds,editState])

    const GetSelectedFieldsData = () => {
        const selectedFields = ids
            .map(id => fields?.filter(field => field.id === id))
            .flat(); 
            setFieldsName(selectedFields);
    };

    const HandleAttachFields = (id) => {
        setIds((prevIds) => {
            if (!Array.isArray(prevIds)) {
                console.error("prevIds is not an array:", prevIds);
                return [id]; // Fallback to a new array with the current ID
            }

            return prevIds.includes(id) 
                ? prevIds.filter(existingId => existingId !== id) 
                : [...prevIds, id];
        });
    };

    const HandleSubmit = () => {
        GetSelectedFieldsData()
        getIDs(ids)
        HandleCloseFields()
        setIds([])
    }

    return (
        <Modal show={showFields} onHide={HandleCloseFields} size='xl' centered>
            <Modal.Header closeButton>
                <Modal.Title className="text-center w-100">{editState ? 'Update' : 'Select'} Fields</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    {fields?.map((item, index) => (
                        <Col md={4} key={index}>
                            <Card>
                                <Card.Header className='p-0'>
                                    <label className="inner-set p-2 rounded-3 d-flex gap-3 custom-dotted-border cursor-pointer">
                                        <CustomCheckbox
                                            label={item?.field_name}
                                            validationMessage="Checkbox is required"
                                            checked={ids?.includes(item.id)}
                                            onChange={(e) => HandleAttachFields(item?.id)} 
                                        />
                                    </label>
                                </Card.Header>
                            </Card>
                        </Col>
                    ))}

                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger"
                    onClick={HandleCloseFields}
                >
                    Discard Changes
                </Button>
                <Button variant="primary" onClick={HandleSubmit}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default AssignFields
