import React, { useEffect, useRef, useState } from 'react'
import { Button, Card, Col, Form, Modal, Row } from 'react-bootstrap'
import useDataTable from '../../../../components/hooks/useDatatable';
import axios from 'axios';
import { useMyContext } from '../../../../Context/MyContextProvider';
import Swal from 'sweetalert2';
import AssignFields from './AssignFields';
import SelectedOptionView from '../Attendee/Fields/SelectedOptionView';
import { EditIcon } from '../CustomHooks/CustomIcon';

const Pages = () => {
    const { api, successAlert, authToken } = useMyContext();
    const [title, setTitle] = useState('');
    const [image, setImage] = useState(null);
    const [attendyRequired, setAttendyRequired] = useState(false);
    const [photoRequired, setPhotoRequired] = useState(false);
    const [pageList, setPageList] = useState();
    const [editState, setEditState] = useState(false)
    const [fieldsName, setFieldsName] = useState([])
    const [status, setStatus] = useState(true);
    const [editId, setEditId] = useState('');

    const columns = [
        { data: "title", title: "Category" },
        {
            title: "Attendy Details Required",
            data: "attendy_required",
            render: function (data) {

                return data === 1 ? 'Yes' : 'No';
            },
        },
        {
            title: "Photo Required",
            data: "photo_required",
            render: function (data) {

                return data === 1 ? 'Yes' : 'No';
            },
        },
        {
            data: null,
            orderable: false,
            searchable: false,
            title: "Action",
            render: function (data) {
                return `<div class="flex align-items-center list-user-action">
                                  <button class="btn btn-sm btn-icon" data-bs-toggle="tooltip" data-bs-placement="top" title="Edit" data-id=${data?.id} data-method="Edit" data-table="action">
                                     <svg fill="none" xmlns="http://www.w3.org/2000/svg" class="icon-20" width="20" height="32" viewBox="0 0 24 24"><path d="M11.4925 2.78906H7.75349C4.67849 2.78906 2.75049 4.96606 2.75049 8.04806V16.3621C2.75049 19.4441 4.66949 21.6211 7.75349 21.6211H16.5775C19.6625 21.6211 21.5815 19.4441 21.5815 16.3621V12.3341" stroke="currentColor" strokeWidth="1.5" stroke-linecap="round" strokeLinejoin="round"></path><path fillRule="evenodd" clipRule="evenodd" d="M8.82812 10.921L16.3011 3.44799C17.2321 2.51799 18.7411 2.51799 19.6721 3.44799L20.8891 4.66499C21.8201 5.59599 21.8201 7.10599 20.8891 8.03599L13.3801 15.545C12.9731 15.952 12.4211 16.181 11.8451 16.181H8.09912L8.19312 12.401C8.20712 11.845 8.43412 11.315 8.82812 10.921Z" stroke="currentColor" strokeWidth="1.5" stroke-linecap="round" strokeLinejoin="round"></path><path d="M15.1655 4.60254L19.7315 9.16854" stroke="currentColor" strokeWidth="1.5" stroke-linecap="round" strokeLinejoin="round"></path></svg>
                                  </button>
                                  <button class="btn btn-sm btn-icon" data-bs-toggle="tooltip" data-bs-placement="top" title="Delete" data-id=${data?.id} data-method="Delete" data-table="action">
                                     <svg fill="none" xmlns="http://www.w3.org/2000/svg" class="icon-20" width="20" height="32" viewBox="0 0 24 24"><path d="M19.3248 9.46826C19.3248 9.46826 18.7818 16.2033 18.4668 19.0403C18.3168 20.3953 17.4798 21.1893 16.1088 21.2143C13.4998 21.2613 10.8878 21.2643 8.27979 21.2093C6.96079 21.1823 6.13779 20.3783 5.99079 19.0473C5.67379 16.1853 5.13379 9.46826 5.13379 9.46826" stroke="currentColor" strokeWidth="1.5" stroke-linecap="round" strokeLinejoin="round"></path><path d="M20.708 6.23975H3.75" stroke="currentColor" strokeWidth="1.5" stroke-linecap="round" strokeLinejoin="round"></path><path d="M17.4406 6.23973C16.6556 6.23973 15.9796 5.68473 15.8256 4.91573L15.5826 3.69973C15.4326 3.13873 14.9246 2.75073 14.3456 2.75073H10.1126C9.53358 2.75073 9.02558 3.13873 8.87558 3.69973L8.63258 4.91573C8.47858 5.68473 7.80258 6.23973 7.01758 6.23973" stroke="currentColor" strokeWidth="1.5" stroke-linecap="round" strokeLinejoin="round"></path></svg>
                                  </button>
                                  </div>
                              `;
            },
        },
    ];

    const tableRef = useRef(null);

    useDataTable({
        tableRef: tableRef,
        columns: columns,
        data: pageList,
        actionCallback: (data) => {
            switch (data.method) {
                case "Edit":
                    handleEdit(data?.id);
                    break;
                case "Delete":
                    HandleDelete(data?.id);
                    break;
                default:
                    break;
            }
        },

    });


    const handleEdit = async (id) => {
        let data = pageList?.find((item) => item?.id === id);
        if (data) {
            setTitle(data?.title || '');
            setImage(data?.image || null);
            setAttendyRequired(data?.attendy_required === 1);
            setPhotoRequired(data?.photo_required === 1);
            setStatus(data?.status === 1);
            setEditState(true);
            setEditId(data?.id);
            const customFieldsArray = data?.catrgotyhas_field?.custom_fields_id?.split(',')?.map(Number);
            setIds(customFieldsArray)
            const fields = data?.fields;
            setFieldsName(fields)
            setShow(true);
        }
    };



    const GetCategories = async () => {
        try {
            const res = await axios.get(`${api}category`, {
                headers: {
                    'Authorization': 'Bearer ' + authToken,
                }
            });
            if (res.data.status) {
                const data = res.data.categoryData;
                setPageList(data)
            }
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        GetCategories()
    }, []);


    const [isLoading, setIsLoading] = useState(false);

    const HandleSubmit = async () => {
        setIsLoading(true)
        try {
            const formData = new FormData();
            formData.append('title', title);
            formData.append('status', status ? 1 : 0);
            formData.append('attendy_required', attendyRequired ? 1 : 0);
            formData.append('photo_required', photoRequired ? 1 : 0);

            // Append image file (if one exists)
            if (image) {
                formData.append('image', image);
            }
            let apiUrl = editState ? `${api}category-update/${editId}` : `${api}category-store`;
            const response = await axios.post(apiUrl, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': 'Bearer ' + authToken,
                }
            });
            if (response.data.status) {
                let id = response.data?.categoryData?.id
                AttachFields(id)
                GetCategories()
                handleClose()
                successAlert(response.data?.message)
            }
        } catch (error) {
            // console.log(error);
        }
    }

    const handleClose = () => {
        setEditState(false);
        setTitle('');
        setImage(null);
        setAttendyRequired(false);
        setPhotoRequired(false);
        setStatus(false);
        setEditId('');
        setShow(false);
    };

    const [show, setShow] = useState();

    const HandleDelete = async (id) => {
        const confirmDelete = await Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
        });

        if (confirmDelete.isConfirmed) {
            let url = `${api}category-destroy/${id}`;
            try {
                const response = await axios.delete(url, {
                    headers: {
                        'Authorization': 'Bearer ' + authToken,
                    }
                });
                if (response.data.status) {
                    Swal.fire('Deleted!', 'Group Deleted Successfully', 'success');
                    GetCategories();
                }
            } catch (error) {
                Swal.fire('Error!', 'An error occurred while deleting the group.', 'error');
            }
        }
    };

    //assing fields states
    // make usestate for show fields state 
    const [showFields, setShowFields] = useState();
    const [ids, setIds] = useState();
    const HandleCloseFields = () => {
        setShowFields(false)
    };

    const HandleAttendy = (checked) => {
        setAttendyRequired(checked);
        if (checked) {
            setShowFields(true)
        }
    }

    const getIDs = (data) => {
        setIds(data)
    }
    const AttachFields = async (id) => {
        try {
            const response = await axios.post(`${api}catrgoty-fields-store`, {
                category_id: id,
                custom_fields_id: ids ? ids : [],
            }, {
                headers: {
                    'Authorization': 'Bearer' + authToken,
                }
            });
            if (response.data.status) {
                Swal.fire('Success!', 'Fields attached successfully', 'success');
                setFieldsName([])
                setIds([])
                GetCategories();
            }
        } catch (error) {
            Swal.fire('Error!', 'An error occurred while attaching fields.', 'error');
        } finally {
            setIsLoading(false);
        }

    }
    return (
        <Row>
            <Modal show={show} onHide={() => handleClose()}>
                <Modal.Header closeButton>
                    <Modal.Title className="text-center w-100">{editState ? 'Edit' : 'New'} Category</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row>
                            <Col lg="12">
                                <Form.Group className="mb-3 form-group">
                                    <Form.Label>Title</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={title}
                                        placeholder="Enter title"
                                        onChange={(e) => setTitle(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>

                            <Col lg="12">
                                <Form.Group className="mb-3 form-group">
                                    <Form.Label>Image : (282 × 260 px)</Form.Label>
                                    <Form.Control
                                        type="file"
                                        onChange={(e) => setImage(e.target.files[0])}
                                    />
                                </Form.Group>
                            </Col>

                            <Col lg="4">
                                <Form.Group className="mb-3 form-group">
                                    <Form.Label>Attendee Required</Form.Label>
                                    <Form.Check
                                        type="switch"
                                        id="attendy-required-switch"
                                        checked={attendyRequired}
                                        onChange={(e) => HandleAttendy(e.target.checked)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg="4">
                                <Form.Group className="mb-3 form-group">
                                    <Form.Label>Photo Required</Form.Label>
                                    <Form.Check
                                        type="switch"
                                        id="photo-required-switch"
                                        checked={photoRequired}
                                        onChange={(e) => setPhotoRequired(e.target.checked)}
                                    />
                                </Form.Group>
                            </Col>

                            <Col lg="4">
                                <Form.Group className="mb-3 form-group">
                                    <Form.Label>Status</Form.Label>
                                    <Form.Check
                                        type="switch"
                                        id="status"
                                        checked={status}
                                        onChange={(e) => setStatus(e.target.checked)}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                    {attendyRequired &&
                        <Col lg="12">
                            <div className="d-flex justify-content-between">
                                <p className='mb-2'>Attendee Fields: </p>
                                <div className="cursor-pointer" onClick={() => setShowFields(true)}>
                                    <EditIcon />
                                </div>
                            </div>
                            <div className='custom-dotted-border p-3'>
                                <Row>
                                    {fieldsName && fieldsName.map((field, i) => (
                                        <Col key={i} md={4} className="mb-2">
                                            <SelectedOptionView item={field.field_name} />
                                        </Col>
                                    ))}
                                </Row>

                            </div>
                        </Col>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger"
                        onClick={handleClose}
                    >
                        Discard Changes
                    </Button>
                    <Button variant="primary" disabled={isLoading} onClick={HandleSubmit}>
                        {isLoading ? 'Loading...' : 'Save'}
                    </Button>
                </Modal.Footer>
            </Modal>
            <AssignFields
                editState={editState}
                showFields={showFields}
                HandleCloseFields={HandleCloseFields}
                getIDs={getIDs}
                existingIds={ids}
                setFieldsName={setFieldsName}
            />
            <Col lg="12">
                <Card>
                    <Card.Header className="d-flex justify-content-between">
                        <div className="header-title">
                            <h4 className="card-title">Catogories</h4>
                        </div>
                        <div className="button">
                            <h4 className="card-title">
                                <Button className="me-4 hvr-curl-top-right border-0" onClick={() => setShow(true)}>
                                    Add New Catogory
                                </Button>
                            </h4>
                        </div>
                    </Card.Header>
                    <Card.Body>
                        <table
                            ref={tableRef}
                            className="table dataTable"
                            data-toggle="data-table"
                        ></table>{" "}
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    )
}

export default Pages