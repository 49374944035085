import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Image, Modal, Nav, Row } from 'react-bootstrap'
import { Link, useLocation } from 'react-router-dom'
import { useMyContext } from '../../../../../../Context/MyContextProvider';
import axios from 'axios';

import Mumbai from '../../../../../../assets/event/stock/cities/mumbai.png';
import Delhi from '../../../../../../assets/event/stock/cities/delhi.png';
import Bengaluru from '../../../../../../assets/event/stock/cities/bangluru.png';
import Hyderabad from '../../../../../../assets/event/stock/cities/hydrabad.png';
import Chandigarh from '../../../../../../assets/event/stock/cities/chandigargh.png';
import Ahmedabad from '../../../../../../assets/event/stock/cities/ahmedabad.png';
// import Chennai from '../../../../../../assets/event/stock/cities/mumbai.png';
// import Kolkata from '../../../../../../assets/event/stock/cities/mumbai.png';
// import Kochi from '../../../../../../assets/event/stock/cities/mumbai.png';
const CustomMenu = ({ handleClose }) => {
    const { UserData, userRole, isMobile, api, createSlug } = useMyContext();
    let location = useLocation();
    const home = userRole === 'User' ? 'dashboard/bookings' : 'dashboard'
    const CloseMenu = () => {
        if (handleClose) {
            handleClose()
        }
    }
    const popularCities = [
        { name: 'Mumbai', icon: Mumbai },
        { name: 'Delhi-NCR', icon: Delhi },
        { name: 'Bengaluru', icon: Bengaluru },
        { name: 'Hyderabad', icon: Hyderabad },
        { name: 'Chandigarh', icon: Chandigarh },
        { name: 'Ahmedabad', icon: Ahmedabad },
        // { name: 'Kolkata', icon: 'path/to/kolkata-icon.png' },
        // { name: 'Chennai', icon: 'path/to/chennai-icon.png' },
        // { name: 'Kochi', icon: 'path/to/kochi-icon.png' },
    ];
    const [show, setShow] = useState(false)
    const [menu, setMenu] = useState()
    const handleCloseModel = () => setShow(false);
    const handleShowMode = () => setShow(true);
    const [userLocation, setUserLocation] = useState(null);
    const [error, setError] = useState(null);
    const detectLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                async (position) => {
                    const { latitude, longitude } = position.coords;
                    try {
                        const response = await axios.get(
                            `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=en`
                        );
                        const city = response.data.city || 'City not found';
                        console.log(city);
                        setUserLocation(city);
                        setError(null);
                    } catch (err) {
                        setError('Error retrieving city name.');
                    }
                },
                (error) => {
                    setError('Unable to retrieve your location.');
                }
            );
        } else {
            setError('Geolocation is not supported by this browser.');
        }
    };
    const cityIconStyle = {
        width: "60px", /* Set desired width */
        height: "60px", /* Set desired height */
        objectFit: "cover" /* Ensures the image covers the entire area */
    }

    const GetMenu = async () => {
        try {
            const res = await axios.get(`${api}active-menu`)
            setMenu(res.data?.menu?.navigation_menu)
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        GetMenu()
    }, []);
    
    const getClassName = (menu, location) => {
        const isActive =
            location.pathname === `/home/dashboard/bookings/${createSlug(menu?.title)}` ||
            location.pathname === `/home/dashboard/${createSlug(menu?.title)}`;
        return `${isActive ? "active" : ""} nav-link`;
    };

    const getLinkTo = (menu) => {
        return menu?.type === 1 ? menu?.external_url : `/home/${createSlug(menu?.title)}`;
    };
    return (
        <>
            {/* model  */}
            <Modal show={show} onHide={handleCloseModel} centered size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title>Select Your City</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="citySearch">
                            <Form.Control type="text" placeholder="Search for your city" />
                        </Form.Group>
                        {/* <Button variant="secondary" className="w-100 my-3" onClick={()=>detectLocation()}> */}
                        <Button variant="primary" className="w-100 my-3" onClick={() => detectLocation()}>
                            Detect my location
                        </Button>
                    </Form>
                    <h5 className="text-center">Popular Cities</h5>
                    <Row>
                        {popularCities.map((city, index) => (
                            <Col xs={4} md={3} lg={2} key={index} className="text-center">
                                <Image src={city.icon} alt={city.name} fluid className="mb-2" style={cityIconStyle} />
                                <p>{city.name}</p>
                            </Col>
                        ))}
                    </Row>
                    {/* <div className="text-center mt-3">
                        <Button variant="link">View All Cities</Button>
                        {popularCities.map((city, index) => (
                            <Col xs={4} md={3} lg={2} key={index} className="text-center">
                                <Image src={city.icon} alt={city.name} fluid className="mb-2" style={cityIconStyle}/>
                                <p>{city.name}</p>
                            </Col>
                        ))}
                    </div> */}
                </Modal.Body>
            </Modal>
            {/* end model */}
            {menu?.map((menu, index) => (
                <Nav.Item as="li" key={index}>
                    <Link
                        target={menu?.new_tab === 1 ? "_blank" : "_self"}
                        className={getClassName(menu, location)}
                        to={getLinkTo(menu)}
                        onClick={() => CloseMenu()}
                    >
                        {menu?.title}
                    </Link>
                </Nav.Item>
            ))}
            {/* <Nav.Item as="li" onClick={() => CloseMenu()}>
                <Link className={`${location.pathname === "/" ? "active" : ""} nav-link `} to="/">
                    <span className="item-name">Home</span>
                </Link>
            </Nav.Item>
            <Nav.Item as="li" onClick={() => CloseMenu()}>
                <Link
                    className={`${location.pathname === "/events"
                        ? "active"
                        : ""
                        } nav-link `}
                    to="/events"
                >
                    {" "}
                    Events{" "}
                </Link>
            </Nav.Item>
            <Nav.Item as="li" onClick={() => CloseMenu()}>
                <Link className={`${location.pathname === "/landing-pages/contact-us"
                    ? "active"
                    : ""
                    } nav-link `}
                    to="/landing-pages/contact-us"
                >
                    Contact
                </Link>
            </Nav.Item> */}

            <div className="alt d-flex justify-content-center gap-1 align-items-center">
                {!isMobile &&
                    <hr className="hr-horizontal" style={{ width: '35px', transform: 'rotate(90deg)' }} />
                }
                <Nav.Item as="li" onClick={() => handleShowMode()}>
                    <div style={{ cursor: 'pointer' }} className='d-flex align-items-center'>
                        <i className="icon">
                            <svg
                                className="icon-20 text-primary"
                                width="20"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M8.53162 2.93677C10.7165 1.66727 13.402 1.68946 15.5664 2.99489C17.7095 4.32691 19.012 6.70418 18.9998 9.26144C18.95 11.8019 17.5533 14.19 15.8075 16.0361C14.7998 17.1064 13.6726 18.0528 12.4488 18.856C12.3228 18.9289 12.1848 18.9777 12.0415 19C11.9036 18.9941 11.7693 18.9534 11.6508 18.8814C9.78243 17.6746 8.14334 16.134 6.81233 14.334C5.69859 12.8314 5.06584 11.016 5 9.13442C4.99856 6.57225 6.34677 4.20627 8.53162 2.93677ZM9.79416 10.1948C10.1617 11.1008 11.0292 11.6918 11.9916 11.6918C12.6221 11.6964 13.2282 11.4438 13.6748 10.9905C14.1214 10.5371 14.3715 9.92064 14.3692 9.27838C14.3726 8.29804 13.7955 7.41231 12.9073 7.03477C12.0191 6.65723 10.995 6.86235 10.3133 7.55435C9.63159 8.24635 9.42664 9.28872 9.79416 10.1948Z"
                                    fill="currentColor"
                                ></path>
                                <ellipse
                                    opacity="0.4"
                                    cx="12"
                                    cy="21"
                                    rx="5"
                                    ry="1"
                                    fill="currentColor"
                                ></ellipse>
                            </svg>
                        </i> &nbsp;
                        <Link className={`nav-link `} o="#"> City </Link>
                    </div>
                </Nav.Item>
                <hr className="hr-horizontal" style={{ width: '35px', transform: 'rotate(90deg)' }} />
                <Nav.Item as="li" onClick={() => CloseMenu()} className=''>
                    <Link
                        className={`${location.pathname === ("/sign-in" || home)
                            ? "active"
                            : ""
                            } nav-link btn btn-secondary text-white px-3 py-1`}
                        to={Object.keys(UserData)?.length > 0 ? home : "/sign-in"}
                    >
                        {Object.keys(UserData)?.length > 0 ? 'Dashboard' : 'Login'}
                    </Link>

                </Nav.Item>
            </div>
        </>
    )
}

export default CustomMenu