import React, { useState, memo, Fragment, useEffect } from "react";
// Router
import { useLocation, useNavigate, useParams } from "react-router-dom";
// React-bootstrap
import { Button, Row, Image, Modal } from "react-bootstrap";
import Swal from "sweetalert2";
//Img
import confirmImage from "../../../../../assets/event/stock/confirm.gif";
import axios from "axios";
import { useMyContext } from "../../../../../Context/MyContextProvider";
import currencyData from '../../../../../JSON/currency.json';
import TicketModal from "../../TicketModal/TicketModal";
import { capitalize } from 'lodash';
import MobileCheckOut from "../StaticComponents/mobileCheckOut";
import Sumary from "./OrderComps/Sumary";
import CheckOutData from "./OrderComps/CheckOutData";


const NewChekout = memo(() => {
    const { api, UserData, isMobile, sendTickets, authToken, formateTemplateTime, ErrorAlert, convertTo12HourFormat, formatDateRange } = useMyContext();
    const [isCheckOut, setIsCheckOut] = useState(true);
    const [currentStep, setCurrentStep] = useState('checkout');
    const navigate = useNavigate('')


    const orderSummary = () => {
        setCurrentStep('orderSummary');
        setIsCheckOut(false);
    };
    const naviagte = useNavigate()


    const { id } = useParams();
    const location = useLocation();
    const [event, setEvent] = useState([]);
    const [currencyMaster, setCurrencyMaster] = useState([]);
    const [selectedTickets, setSelectedTickets] = useState();
    const [bookingHistory, setBookingHistory] = useState([]);
    const [ticketData, setTicketData] = useState();
    const [code, setCode] = useState('');
    const [appliedPromoCode, setAppliedPromoCode] = useState('');
    const [subtotal, setSubTotal] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [ticketCurrency, setTicketCurrency] = useState('₹');
    const [totalTax, setTotalTax] = useState(0);
    const [grandTotal, setGrandTotal] = useState(0);
    const [baseAmount, setBaseAmount] = useState(0);
    const [centralGST, setCentralGST] = useState(0);
    const [stateGST, setStateGST] = useState(0);
    const [setConvenienceFee, convenienceFee] = useState(0);
    const [downladTicketType, setDownladTicketType] = useState('');
    const [ticketSummary, setTicketSummary] = useState([]);
    const [disableChoice, setDisableChoice] = useState(false);
    const [totalDiscount, setTotalDiscount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [selectedTicketID, setSelectedTicketID] = useState(null);
    const [resetCounterTrigger, setRsetCounterTrigger] = useState(0)
    const [categoryData, setCategoryData] = useState(null);
    const [attendeeState, setAttendeeState] = useState(false);
    // make state for isAttendeeRequired
    const [isAttendeeRequired, setIsAttendeeRequired] = useState(false);
    const [disable, setDisable] = useState(false);
    const [attendees, setAttendees] = useState([]);
    const getTicketData = async () => {
        await axios.get(`${api}event-detail/${id}`, {
            headers: {
                'Authorization': 'Bearer ' + authToken,
            }
        })
            .then((res) => {
                if (res.data.status) {
                    setEvent(res.data.events)
                }
            }).catch((err) =>
                console.log(err)
            )
    }
    const fetchCategoryData = async () => {
        try {
            const response = await axios.get(`${api}category-title/${event.category}`);
            setCategoryData(response.data);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        getTicketData()
        setCurrencyMaster(currencyData)
        if (isMobile) {
            setIsCheckOut(true)
        }
    }, [])

    useEffect(() => {
        if (event?.category) {
            fetchCategoryData()
        }
    }, [event]);

    useEffect(() => {
        if (categoryData) {
            AttendyView()
        }
    }, [categoryData]);

    const AttendyView = () => {
        setIsAttendeeRequired(categoryData?.categoryData?.attendy_required === 1);
    }
    const getCurrencySymbol = (currency) => {
        if (currencyMaster && currency) {

            if (currencyMaster.hasOwnProperty(currency)) {
                let symbol = currencyMaster[currency]?.symbol;
                return symbol;
            }
        }
    }

    const getTicketCount = (quantity, category, price, id) => {
        if (selectedTicketID && selectedTicketID !== id && quantity > 0) {
            setRsetCounterTrigger(prev => prev + 1);
        }
        setSelectedTicketID(id);
        setDiscount(0)
        setAppliedPromoCode('')
        setCode('')
        setSelectedTickets({ category, quantity, price, id });
    };

    useEffect(() => {
        if (selectedTickets?.quantity > 0) {
            let price = selectedTickets?.sale === 'true' ? selectedTickets?.sale_price : selectedTickets?.price;
            const totalPriceForCategory = price * selectedTickets?.quantity;
            setSubTotal(totalPriceForCategory);
        } else {
            setSubTotal(0);
            setBaseAmount(0);
            setCentralGST(0);
            setStateGST(0);
            setTotalTax(0);
            setGrandTotal(0);
        }
    }, [selectedTickets]);


    const [discountType, setDiscountType] = useState();



    const applyPromode = async () => {
        try {
            const res = await axios.post(`${api}check-promo-code/${event?.user_id}`, {
                amount: grandTotal,
                promo_code: code,
            }, {
                headers: {
                    'Authorization': 'Bearer ' + authToken,
                }
            });
            if (res.data.status) {
                const data = res.data.promo_data
                setDiscount(data?.discount_value)
                setDiscountType(data?.discount_type)
                setAppliedPromoCode(code)
                Sweetalert()
                //setCode('')
            } else {
                SweetalertError(res.data.message)
            }
        } catch (err) {
            SweetalertError(err.response.data.message)
        }
    };

    const handleRemovePromocode = () => {
        setDiscount(0)
        setCode('')
    };

    useEffect(() => {
        if (subtotal) {
            // Calculate the base amount based on the subtotal
            let baseAmount = subtotal; // Use subtotal as the base amount
            setBaseAmount(baseAmount);

            // Calculate Central GST and State GST
            let centralGST = baseAmount * 9 / 100;
            let stateGST = baseAmount * 9 / 100;
            setCentralGST(centralGST);
            setStateGST(stateGST);

            // Calculate total tax
            let tax = centralGST + stateGST;
            setTotalTax(tax > 0 ? tax?.toFixed(2) : 0);
        }

        // Apply discount as a percentage if a discount is present
        let discountAmount = 0; // Initialize discountAmount

        // Apply discount based on the type
        if (discount > 0) { // Ensure discount is greater than 0 to calculate
            if (discountType === "percentage") {
                discountAmount = subtotal * discount / 100; // Calculate percentage discount
            } else if (discountType === "fixed") {
                discountAmount = discount; // Use the fixed discount amount
            }
        }

        // Calculate the grand total only if totalTax and subtotal are available
        if (subtotal && totalTax) {
            let total = (subtotal + +totalTax) - discountAmount;
            // Ensure grand total is not negative
            setTotalDiscount(discountAmount)
            setGrandTotal(total > 0 ? total.toFixed(2) : '0.00');
        }
    }, [subtotal, totalTax, discount, discountType]);


    const [masterBookings, setMasterBookings] = useState([]);
    const [normalBookings, setNormalBookings] = useState([]);
    const [mainBookings, setMainBookings] = useState(false);
    const [isMaster, setIsMaster] = useState(false);


    const HandleSendTicket = (data) => {
        sendTickets(data, 'new')
    }
    useEffect(() => {
        if (bookingHistory.length > 0) {
            // Group tickets by category and sum quantities
            const ticketMap = bookingHistory.reduce((acc, booking) => {
                const ticket = event.tickets?.find(item => item.id === booking.ticket_id);
                if (ticket) {
                    if (!acc[ticket.name]) {
                        acc[ticket.name] = { ...ticket, quantity: 0 };
                    }
                    acc[ticket.name].quantity += 1; // Assuming each booking represents one ticket
                }
                return acc;
            }, {});

            // Convert the map to an array
            const ticketsData = Object.values(ticketMap);
            setTicketSummary(ticketsData);
        }
    }, [bookingHistory, event.tickets]);


    function Sweetalert() {
        Swal.fire({
            icon: "success",
            title: "Applied Success!",
            text: "Promocode applied succesfully.",
        });
    }
    function SweetalertError(message) {
        Swal.fire({
            icon: "error",
            // title: "Something went wrong",
            text: capitalize(message),
        });
    }

    //model states
    const [show, setShow] = useState(false);
    function handleclose() {
        setShow(false)
        orderSummary()
    }


    useEffect(() => {
        if (downladTicketType) {
            Swal.fire({
                title: "Are you sure?",
                text: "While selecting a ticket type make sure to its can't changable!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, Generate Ticket!"
            }).then((result) => {
                if (result.isConfirmed) {
                    Swal.fire({
                        title: "Generated!",
                        text: "Your Ticket Generate Successfully",
                        icon: "success"
                    });
                    setDisableChoice(true)
                    HandleSelectTicketType(downladTicketType)
                    setTicketShow(true)
                }
            });
        }
    }, [downladTicketType]);



    const HandleSelectTicketType = (type) => {
        // if (type === 'combine') {
        //     setTicketData(mainBookings);
        // } else if (type === 'individual') {
        //     const master = masterBookings;
        //     setTicketData(master);
        // }
    }


    const getTicketPrice = (category) => {
        let ticket = event?.tickets?.find((item) => item.name === category)
        let price = ticket?.sale === 'true' ? ticket?.sale_price : ticket?.price
        return price
    }


    // const [status, setStatus] = useState('');
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const session_id = queryParams.get('session_id');
        if (session_id) {
            if (session_id) {
                handleBooking();
            } else {
                Swal.fire({
                    title: 'Payment Failed',
                    text: 'Unfortunately, your payment could not be processed.',
                    icon: 'error',
                    showCancelButton: true,
                    confirmButtonText: '<i class="fas fa-redo-alt"></i> Proceed Again',
                    cancelButtonText: '<i class="fas fa-home"></i> Go To Home'
                }).then((result) => {
                    if (result.isConfirmed) {
                        Swal.close();
                    } else if (result.dismiss === Swal.DismissReason.cancel) {
                        naviagte('/');
                    }
                });
            }
        }
    }, [location]);




    const [ticketShow, setTicketShow] = useState(false);
    function handlecloseTickets() {
        setTicketShow(false)
    }


    useEffect(() => {
        if (isMaster) {
            setMainBookings(masterBookings?.bookings)
        } else {
            setMainBookings(normalBookings)
        }
    }, [masterBookings, normalBookings]);

    useEffect(() => {
        if (mainBookings?.length > 0) {
            PrePareMailData(mainBookings);
        }
    }, [mainBookings]);

    const PrePareMailData = async (data) => {
        if (data?.length) {
            const Booking = data?.map((item) => {
                // Extracting common fields
                const number = item?.number ?? item?.bookings?.[0]?.number ?? 'Unknown';
                const email = item?.email ?? item?.bookings?.[0]?.email ?? 'Unknown';
                const thumbnail = item?.ticket?.event?.thumbnail ?? item?.bookings?.[0]?.ticket?.event?.thumbnail ?? 'https://smsforyou.biz/ticketcopy.jpg';
                const name = item?.user?.name ?? item?.bookings?.[0]?.user?.name ?? 'Guest';
                const qty = item?.bookings?.length ?? 1;
                const category = item?.ticket?.name ?? item?.bookings?.[0]?.ticket?.name ?? 'General';
                const eventName = item?.ticket?.event?.name ?? item?.bookings?.[0]?.ticket?.event?.name ?? 'Event';
                const eventDate = item?.ticket?.event?.date_range ?? item?.bookings?.[0]?.ticket?.event?.date_range ?? 'TBD';
                const eventTime = item?.ticket?.event?.start_time ?? item?.bookings?.[0]?.ticket?.event?.start_time ?? 'TBD';
                const address = item?.ticket?.event?.address ?? item?.bookings?.[0]?.ticket?.event?.address ?? 'No Address Provided';
                const location = address.replace(/,/g, '|');
                const DateTime = formateTemplateTime(eventDate, eventTime);
                let price = getTicketPrice(category) * qty?.toFixed(2);
                const total = item?.amount ?? item?.bookings?.[0]?.amount ?? 0;
                const convenience_fee = item?.total_tax ?? item?.bookings?.[0]?.total_tax ?? 0;
                return {
                    // allTicketTotal,
                    email,
                    number, // Assuming you want to include this
                    thumbnail,
                    category,
                    qty,
                    name,
                    eventName,
                    eventDate,
                    eventTime,
                    DateTime,
                    address,
                    location,
                    price,
                    convenience_fee: convenience_fee,
                    total
                    // Include any other necessary fields
                };
            });
            if (Booking?.length > 0) {
                sendMail(Booking);
            }
        }
    }
    const [mailSend, setMailSend] = useState(false);

    // send Email verification
    const sendMail = async (data) => {
        try {
            const res = await axios.post(`${api}booking-mail/${UserData?.id}`, { data }, {
                headers: {
                    'Authorization': 'Bearer ' + authToken,
                }
            });
            if (res.data?.status) {
                setMailSend(true)
            }
        } catch (err) {
            console.log(err);
        }
    }


    const handlePayment = async () => {
        if (selectedTickets?.quantity > 0) {
            if (categoryData?.categoryData?.attendy_required === 1 && !attendeeState) {
                setIsAttendeeRequired(false);
                setDisable(true)
                setAttendeeState(true)
            } else {
                ProcessBooking()
            }
        } else {
            ErrorAlert('Please select a ticket first')
        }
    }


    const ProcessBooking = async () => {
        const validTickets = selectedTickets?.quantity > 0;
        if (!validTickets) {
            ErrorAlert('Please Select A Ticket')
        } else {
            console.log(attendees);
            const formData = new FormData();

            attendees?.forEach((attendee, index) => {
                Object.entries(attendee).forEach(([fieldKey, fieldValue]) => {
                    formData.append(`attendees[${index}][${fieldKey}]`, fieldValue);
                });
            });
            const requestData = {
                user_id: UserData?.id,
                email: UserData?.email,
                number: UserData?.number,
                name: UserData?.name,
                payment_method: 'online',
                amount: grandTotal,
                // convenience_fee: convenienceFee,
                base_amount: subtotal,
                tickets: selectedTickets,
                type: event?.event_type
            };
            setLoading(true);
            setError('');
            try {
                formData.append('amount', grandTotal);
                formData.append('productinfo', event?.name);
                formData.append('event_id', event?.event_key);
                formData.append('firstname', UserData?.name);
                formData.append('phone', UserData?.number);
                formData.append('promo_code', code);
                formData.append('total_tax', totalTax);
                formData.append('discount', totalDiscount);
                formData.append('email', UserData?.email);
                formData.append('organizer_id', event?.user?.id);
                formData.append('requestData', JSON.stringify(requestData));

                const response = await axios.post(`${api}initiate-payment`, formData, {
                    headers: {
                        'Authorization': 'Bearer ' + authToken,
                    }
                });
                if (response.data?.result?.status === 1) {
                    const sessionData = {
                        session_id: response.data.txnid, // Store the transaction ID
                        selectedTickets: selectedTickets, // Store selected tickets
                    };
                    localStorage.setItem('ticketSession', JSON.stringify(sessionData));
                    let code = response.data?.result?.data
                    window.location.href = 'https://testpay.easebuzz.in/pay/' + code;
                } else {
                    setError('Payment initiation failed');
                }
            } catch (error) {
                setError('An error occurred while initiating payment.');
            } finally {
                setLoading(false);
            }
        }
    }

    const handleBooking = async () => {
        const queryParams = new URLSearchParams(window.location.search);
        const sessionId = queryParams.get('session_id');
        const payment_status = queryParams.get('status');
        //window.history.pushState({}, '', window.location.pathname);
        if (payment_status === 'failure') {
            ErrorAlert('Payment failed. Please try again.');
        } else if (payment_status === 'userCancelled') {
            ErrorAlert('Payment was canceled. Please initiate payment again if required.');
            return false
        }
        if (sessionId && payment_status === 'success') {
            try {
                const res = await axios.post(`${api}verify-booking`, { session_id: sessionId }, {
                    headers: {
                        'Authorization': 'Bearer ' + authToken,
                    }
                });
                if (res.data.status) {
                    const master = res.data.isMaster;
                    setIsMaster(master)
                    const bookings = res.data.bookings;
                    setTicketData(bookings[0])
                    let bookingsToCheck = null;
                    if (master) {
                        bookingsToCheck = Array.isArray(res.data.bookings[0].bookings)
                            ? res.data.bookings[0].bookings
                            : [];
                    } else {
                        bookingsToCheck = Array.isArray(res.data.bookings)
                            ? res.data.bookings : [];
                    }
                    if (bookingsToCheck.length > 0 && bookingsToCheck.every(booking => parseInt(booking.payment_status) === 1)) {
                        const storedData = localStorage.getItem('ticketSession');
                        const { session_id, selectedTickets } = JSON.parse(storedData);
                        if (parseInt(session_id) === parseInt(master ? bookings[0].bookings[0]?.txnid : bookings[0]?.txnid)) {
                            setSelectedTickets(selectedTickets);
                        }
                        if (master) {
                            setMasterBookings(bookings[0])
                            //HandleSendTicket(bookingsToCheck[0])
                        } else {
                            setNormalBookings(bookingsToCheck);
                            // HandleSendTicket(bookingsToCheck[0])
                        }
                        setShow(true);
                    } else {
                        ErrorAlert('Something Went Wrong.');
                    }
                } else {
                    ErrorAlert('Booking verification failed. Please try again.');
                }
            } catch (err) {
                ErrorAlert('An error occurred while verifying your booking.');
            }
        }
    };
    const [allAttendees, setAllAttendees] = useState([]);
    useEffect(() => {
        if(mainBookings){

            const combinedAttendees = mainBookings
            ?.map(booking => booking.attendee)
            ?.flat();
            setAllAttendees(combinedAttendees)
        }
    }, [mainBookings]);
    return (
        <Fragment>
            {/* success model  */}
            <Modal show={show} backdrop="static" centered>
                <Modal.Body>
                    <div className="d-flex flex-column justify-content-center py-3">
                        <h3 className="text-center">Booking Confirmed!</h3>
                        <span className="text-center">
                            <Image src={confirmImage} width={200} />
                        </span>
                        <h4 className="text-center">Thank You For Your Booking!</h4>
                        <div className="text-center">
                            <Button className="border rounded-pill w-50" onClick={() => handleclose()}>Booking Summary</Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            {/* success model end */}

            {/* Ticket model  */}
            <TicketModal
                show={ticketShow}
                handleCloseModal={handlecloseTickets}
                ticketType={downladTicketType}
                ticketData={ticketData}
                formatDateRange={formatDateRange}
            />
            {/* Ticket model end */}
            <Row>
                {
                    (isMobile && isCheckOut) &&
                    <MobileCheckOut
                        ticketCurrency={ticketCurrency}
                        handlePayment={handlePayment}
                        grandTotal={grandTotal}
                        isAttendeeRequired={isAttendeeRequired}
                    />
                }
                {
                    isCheckOut &&
                    <CheckOutData
                        categoryData={categoryData}
                        setDisable={setDisable}
                        disable={disable}
                        setAttendees={setAttendees}
                        AttendyView={AttendyView}
                        attendeeState={attendeeState}
                        setAttendeeState={setAttendeeState}
                        event={event}
                        selectedTickets={selectedTickets}
                        currentStep={currentStep}
                        error={error}
                        isMobile={isMobile}
                        resetCounterTrigger={resetCounterTrigger}
                        getTicketCount={getTicketCount}
                        getCurrencySymbol={getCurrencySymbol}
                        code={code}
                        setCode={setCode}
                        applyPromode={applyPromode}
                        discount={discount}
                        appliedPromoCode={appliedPromoCode}
                        ticketCurrency={ticketCurrency}
                        subtotal={subtotal}
                        handleRemovePromocode={handleRemovePromocode}
                        totalDiscount={totalDiscount}
                        baseAmount={baseAmount}
                        centralGST={centralGST}
                        totalTax={totalTax}
                        grandTotal={grandTotal}
                        handlePayment={handlePayment}
                        isAttendeeRequired={isAttendeeRequired}
                    />
                }
                {
                    currentStep === 'orderSummary' &&
                    <Sumary
                        event={event}
                        attendeeList={allAttendees}
                        apiData={categoryData?.customFieldsData}
                        mainBookings={mainBookings}
                        selectedTickets={selectedTickets}
                        isMaster={isMaster}
                        disableChoice={disableChoice}
                        setDownladTicketType={setDownladTicketType}
                        navigate={navigate}
                        currentStep={currentStep}
                        convertTo12HourFormat={convertTo12HourFormat}
                    />
                }
            </Row>
        </Fragment>
    );
});

NewChekout.displayName = "NewChekout";
export default NewChekout;
