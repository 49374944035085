import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useMyContext } from '../../../../../Context/MyContextProvider';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from 'react-router-dom';

const Banners = () => {
    const { isMobile, api } = useMyContext();
    const [banners, setBanners] = useState({ mobile: [], pc: [] });
    const fetchBanners = async () => {
      try {
        // Check if banners exist in local storage
        const storedBanners = JSON.parse(localStorage.getItem('banners'));
        const storedLastUpdated = localStorage.getItem('banners_last_updated');
  
        // Fetch banners from the server
        const response = await axios.get(`${api}banners`);
        const bannersData = response.data.banners;
        const serverLastUpdated = response.data.last_updated;
  
        // If stored banners exist and are up to date, use them
        if (storedBanners && storedLastUpdated === serverLastUpdated) {
          setBanners(storedBanners);
        } else {
          // If not, or if there's an update, use the fetched data and update local storage
          const mobileBanners = bannersData.map(banner => ({
            url: banner.mobileUrl,
            type: banner.type,
            src: banner?.redirectUrl
          }));
  
          const pcBanners = bannersData.map(banner => ({
            url: banner.pcUrl,
            type: banner.type,
            src: banner?.redirectUrl
          }));
  
          const banners = {
            mobile: mobileBanners,
            pc: pcBanners,
          };
  
          // Set the banners in state
          setBanners(banners);
  
          // Store the fetched banners and last updated timestamp in local storage
          localStorage.setItem('banners', JSON.stringify(banners));
          localStorage.setItem('banners_last_updated', serverLastUpdated);
        }
      } catch (error) {
        //console.error('Error fetching banners:', error);
        // Handle errors as needed
      }
    };
  
    useEffect(() => {
      fetchBanners()
    }, []);
  
    const displayedBanners = isMobile ? banners.mobile : banners.pc;
  
    return (
        <div className="banner">
            <Swiper
                className="p-0 m-0 swiper-wrapper list-inline"
                slidesPerView={1}
                loop={true}
                centeredSlides={true}
                autoplay={true}
                spaceBetween={8}
                pagination={{
                    clickable: true,
                }}
            >
                {displayedBanners?.map((banner, index) => {
                    const bannerStyle = {
                        background: `url(${banner?.url}) no-repeat center center`,
                        backgroundSize: 'cover',
                        height: isMobile ? '18.5rem'
                            : ''
                        // '28.5rem'
                        ,
                    };
                    return (
                        <SwiperSlide className="card-slide" key={index}>
                            <Link to={banner?.src}>
                                <div className="position-relative video-box" style={bannerStyle}>
                                    <div className="iq-popup-video">
                                        <div className="iq-video-icon position-absolute">
                                            {banner?.type === 'video' &&
                                                <>
                                                    <div className="iq-video bg-secondary position-absolute text-center d-inline-block iq-fslightbox-img">
                                                        <Link to="https://smsforyou.biz/GYT.mp4" className="d-blok">
                                                            <svg
                                                                width="48"
                                                                className="text-white"
                                                                viewBox="0 0 24 24"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg">
                                                                <path
                                                                    d="M16 12.0049C16 12.2576 15.9205 12.5113 15.7614 12.7145C15.7315 12.7543 15.5923 12.9186 15.483 13.0255L15.4233 13.0838C14.5881 13.9694 12.5099 15.3011 11.456 15.7278C11.456 15.7375 10.8295 15.9913 10.5312 16H10.4915C10.0341 16 9.60653 15.7482 9.38778 15.34C9.26847 15.1154 9.15909 14.4642 9.14915 14.4554C9.05966 13.8712 9 12.9769 9 11.9951C9 10.9657 9.05966 10.0316 9.16903 9.45808C9.16903 9.44836 9.27841 8.92345 9.34801 8.74848C9.45739 8.49672 9.65625 8.2819 9.90483 8.14581C10.1037 8.04957 10.3125 8 10.5312 8C10.7599 8.01069 11.1875 8.15553 11.3565 8.22357C12.4702 8.65128 14.598 10.051 15.4134 10.9064C15.5526 11.0425 15.7017 11.2087 15.7415 11.2467C15.9105 11.4605 16 11.723 16 12.0049Z"
                                                                    fill="currentColor"
                                                                ></path>
                                                            </svg>
                                                        </Link>
                                                    </div>
                                                    <div className="waves"></div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </SwiperSlide>
                    );
                })}
            </Swiper>
        </div>
    )
}

export default Banners