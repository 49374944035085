import React from 'react'
import { Row, Col, Card, Alert, Image, Form, Button } from 'react-bootstrap';
import { TrueCircleIcon } from '../../../CustomHooks/CustomIcon';
import partyImage from "../../../../../../assets/modules/e-commerce/images/product/party3.jpg";
import BookingsAttendee from './BookingsAttendee';
const Sumary = ({
    event,
    mainBookings,
    selectedTickets,
    isMaster,
    disableChoice,
    setDownladTicketType,
    navigate,
    currentStep,
    convertTo12HourFormat,
    apiData,
    attendeeList
}) => {

    return (
        <div id="order-summary" className={`iq-product-tracker-card b-0 ${currentStep === 'orderSummary' ? 'show' : ''}`}>
            <Row>
                <Col lg={6} md={6} xl={6}>
                    <Card>
                        <Card.Header>
                            <h4 className="mb-0">Booking Summary</h4>
                        </Card.Header>
                        <Card.Body>
                            <div className="">
                                <div className="Main">
                                    <Row>
                                        <Col lg='12'>
                                            <Alert variant="success d-flex flex-column" role="alert">

                                                <div className="d-flex mb-2">
                                                    <TrueCircleIcon />
                                                    <strong>Hurray! Booking Success</strong>
                                                </div>
                                                <div>
                                                    <p className="p-0 m-0">Check Email/SMS/WhatsApp For Confirmation.</p>
                                                </div>
                                            </Alert>
                                        </Col>

                                        <Col>
                                            <Row>
                                                <Col lg='3'>
                                                    <Image src={event?.thumbnail || partyImage}
                                                        width={200}
                                                        alt="product-details"
                                                        className="img-fluid iq-product-img hover-media border rounded-4"
                                                    ></Image>

                                                </Col>
                                                <Col lg='9'>
                                                    <div className="d-flex flex-column gap-2">
                                                        <div className="item d-flex gap-2 mt-3">
                                                            <div className="icon">
                                                                <svg fill="none" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                                    <path fillRule="evenodd" clipRule="evenodd" d="M16.3345 2.75024H7.66549C4.64449 2.75024 2.75049 4.88924 2.75049 7.91624V16.0842C2.75049 19.1112 4.63549 21.2502 7.66549 21.2502H16.3335C19.3645 21.2502 21.2505 19.1112 21.2505 16.0842V7.91624C21.2505 4.88924 19.3645 2.75024 16.3345 2.75024Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                                    <path d="M8.43994 12.0002L10.8139 14.3732L15.5599 9.6272" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                                </svg>
                                                            </div>
                                                            <div className="data">Name : <strong>{event?.name}</strong></div>
                                                        </div>
                                                        <div className="item d-flex gap-2">
                                                            <div className="icon">
                                                                <svg fill="none" xmlns="http://www.w3.org/2000/svg" className="icon-32" width="32" height="32" viewBox="0 0 24 24"><path fillRule="evenodd" clipRule="evenodd" d="M14.5 10.5005C14.5 9.11924 13.3808 8 12.0005 8C10.6192 8 9.5 9.11924 9.5 10.5005C9.5 11.8808 10.6192 13 12.0005 13C13.3808 13 14.5 11.8808 14.5 10.5005Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path><path fillRule="evenodd" clipRule="evenodd" d="M11.9995 21C10.801 21 4.5 15.8984 4.5 10.5633C4.5 6.38664 7.8571 3 11.9995 3C16.1419 3 19.5 6.38664 19.5 10.5633C19.5 15.8984 13.198 21 11.9995 21Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                                                            </div>
                                                            <div className="data">Venue : <strong>{event?.address}</strong></div>
                                                        </div>
                                                        <div className="item d-flex gap-2">
                                                            <div className="item d-flex gap-2">
                                                                <div className="icon">
                                                                    <svg fill="none" xmlns="http://www.w3.org/2000/svg" className="icon-32" width="32" height="32" viewBox="0 0 24 24"><path fillRule="evenodd" clipRule="evenodd" d="M16.3345 2.75024H7.66549C4.64449 2.75024 2.75049 4.88924 2.75049 7.91624V16.0842C2.75049 19.1112 4.63449 21.2502 7.66549 21.2502H16.3335C19.3645 21.2502 21.2505 19.1112 21.2505 16.0842V7.91624C21.2505 4.88924 19.3645 2.75024 16.3345 2.75024Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path><path d="M15.391 14.0178L12 11.9948V7.63379" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                                                                </div>
                                                                <div className="data">Time : <strong>{convertTo12HourFormat(event?.start_time)}</strong></div>
                                                            </div> |
                                                            <div className="item d-flex gap-2">
                                                                <div className="icon">
                                                                    <svg fill="none" xmlns="http://www.w3.org/2000/svg" className="icon-32" width="32" height="32" viewBox="0 0 24 24"><path d="M11.9951 16.6766V14.1396" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path><path fillRule="evenodd" clipRule="evenodd" d="M18.19 5.33008C19.88 5.33008 21.24 6.70008 21.24 8.39008V11.8301C18.78 13.2701 15.53 14.1401 11.99 14.1401C8.45 14.1401 5.21 13.2701 2.75 11.8301V8.38008C2.75 6.69008 4.12 5.33008 5.81 5.33008H18.19Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path><path d="M15.4951 5.32576V4.95976C15.4951 3.73976 14.5051 2.74976 13.2851 2.74976H10.7051C9.48512 2.74976 8.49512 3.73976 8.49512 4.95976V5.32576" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path><path d="M2.77441 15.4829L2.96341 17.9919C3.09141 19.6829 4.50041 20.9899 6.19541 20.9899H17.7944C19.4894 20.9899 20.8984 19.6829 21.0264 17.9919L21.2154 15.4829" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                                                                </div>
                                                                <div className="data">
                                                                    Ticket QTY : <strong>{selectedTickets?.quantity || 1}</strong>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="item d-flex gap-2">
                                                            <div className="icon">
                                                                <svg fill="none" xmlns="http://www.w3.org/2000/svg" className="icon-32" width="32" height="32" viewBox="0 0 24 24"><path d="M13.8496 4.25024V6.67024" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path><path d="M13.8496 17.76V19.784" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path><path d="M13.8496 14.3247V9.50366" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path><path fillRule="evenodd" clipRule="evenodd" d="M18.7021 20C20.5242 20 22 18.5426 22 16.7431V14.1506C20.7943 14.1506 19.8233 13.1917 19.8233 12.001C19.8233 10.8104 20.7943 9.85039 22 9.85039L21.999 7.25686C21.999 5.45745 20.5221 4 18.7011 4H5.29892C3.47789 4 2.00104 5.45745 2.00104 7.25686L2 9.93485C3.20567 9.93485 4.17668 10.8104 4.17668 12.001C4.17668 13.1917 3.20567 14.1506 2 14.1506V16.7431C2 18.5426 3.4758 20 5.29787 20H18.7021Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                                                            </div>
                                                            Ticket Type : <strong>
                                                                {selectedTickets?.category}
                                                            </strong>
                                                        </div>
                                                        <div className="item d-flex gap-2">
                                                            <div className="icon">
                                                                <svg fill="none" xmlns="http://www.w3.org/2000/svg" className="icon-32" width="32" height="32" viewBox="0 0 24 24"><path d="M2.75 3.25L4.83 3.61L5.793 15.083C5.87 16.02 6.653 16.739 7.593 16.736H18.502C19.399 16.738 20.16 16.078 20.287 15.19L21.236 8.632C21.342 7.899 20.833 7.219 20.101 7.113C20.037 7.104 5.164 7.099 5.164 7.099" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path><path d="M14.125 10.7949H16.898" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path><path fillRule="evenodd" clipRule="evenodd" d="M7.15435 20.2026C7.45535 20.2026 7.69835 20.4466 7.69835 20.7466C7.69835 21.0476 7.45535 21.2916 7.15435 21.2916C6.85335 21.2916 6.61035 21.0476 6.61035 20.7466C6.61035 20.4466 6.85335 20.2026 7.15435 20.2026Z" fill="currentColor" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path><path fillRule="evenodd" clipRule="evenodd" d="M18.4346 20.2026C18.7356 20.2026 18.9796 20.4466 18.9796 20.7466C18.9796 21.0476 18.7356 21.2916 18.4346 21.2916C18.1336 21.2916 17.8906 21.0476 17.8906 20.7466C17.8906 20.4466 18.1336 20.2026 18.4346 20.2026Z" fill="currentColor" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                                                            </div>
                                                            <div className="data">Total Amount : <strong>₹{mainBookings[0]?.amount || mainBookings?.amount}</strong></div>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col lg='12'>
                                                    <div className="radio-group text-center">
                                                        <div key={`inline-${'radio'}`} className="mb-3">
                                                            <label className="text-dark me-2">Download Ticket</label>
                                                            {isMaster &&
                                                                <Form.Check
                                                                    inline
                                                                    label="Individual"
                                                                    name="group1"
                                                                    type={'radio'}
                                                                    id={`inline-${'radio'}-1`}
                                                                    value={'individual'}
                                                                    // disabled={disableChoice}
                                                                    onChange={(e) => setDownladTicketType({ type: e.target.value })}
                                                                />
                                                            }
                                                            <Form.Check
                                                                inline
                                                                label="Combine"
                                                                name="group1"
                                                                type={'radio'}
                                                                value={'combine'}
                                                                id={`inline-${'radio'}-2`}
                                                                // disabled={disableChoice}
                                                                onChange={(e) => setDownladTicketType({ type: e.target.value })}
                                                            />
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    {
                                                        disableChoice &&
                                                        <div className="d-flex justify-content-center">
                                                            <Button variant="success py-2 px-4 m-0  d-flex align-items-center gap-1" onClick={() => navigate('/dashboard/bookings')}>
                                                                <div style={{ cursor: 'pointer', marginTop: '-2px' }} className="download-btn">
                                                                    <div className="icon">
                                                                        <svg fill="none" xmlns="http://www.w3.org/2000/svg" className="icon-32" width="32" height="32" viewBox="0 0 24 24"><path d="M2.75 3.25L4.83 3.61L5.793 15.083C5.87 16.02 6.653 16.739 7.593 16.736H18.502C19.399 16.738 20.16 16.078 20.287 15.19L21.236 8.632C21.342 7.899 20.833 7.219 20.101 7.113C20.037 7.104 5.164 7.099 5.164 7.099" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path><path d="M14.125 10.7949H16.898" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path><path fillRule="evenodd" clipRule="evenodd" d="M7.15435 20.2026C7.45535 20.2026 7.69835 20.4466 7.69835 20.7466C7.69835 21.0476 7.45535 21.2916 7.15435 21.2916C6.85335 21.2916 6.61035 21.0476 6.61035 20.7466C6.61035 20.4466 6.85335 20.2026 7.15435 20.2026Z" fill="currentColor" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path><path fillRule="evenodd" clipRule="evenodd" d="M18.4346 20.2026C18.7356 20.2026 18.9796 20.4466 18.9796 20.7466C18.9796 21.0476 18.7356 21.2916 18.4346 21.2916C18.1336 21.2916 17.8906 21.0476 17.8906 20.7466C17.8906 20.4466 18.1336 20.2026 18.4346 20.2026Z" fill="currentColor" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                                                                    </div>
                                                                </div>
                                                                View My Bookings
                                                            </Button>
                                                        </div>
                                                    }
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={6} md={6} xl={6}>
                    <Card>
                        <Card.Header>Attendee Details</Card.Header>
                        <Card.Body>
                            <BookingsAttendee
                                attendeeList={attendeeList}
                                apiData={apiData}
                                ShowAction={false}
                            />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default Sumary