import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { Accordion, Button, Card, Col, Form, Modal, Row } from 'react-bootstrap'
import useDataTable from '../../../../components/hooks/useDatatable';
import axios from 'axios';
import { useMyContext } from '../../../../Context/MyContextProvider';
import CustomIconButton from '../CustomComponents/CustomIconButton';
import { DleteIcon, EditIcon } from '../CustomHooks/CustomIcon';
const FooterGroups = () => {
    const { api, successAlert, authToken } = useMyContext();
    const [title, setTitle] = useState();
    const [groupList, setgroupList] = useState([]);
    const [editState, setEditState] = useState(false)
    const [status, setStatus] = useState();
    const [editId, setEidtId] = useState('');
    const [pages, setPages] = useState([])
    const [activeKeys, setActiveKeys] = useState([]);
    useEffect(() => {
        const accordionButtons = document.querySelectorAll('.accordion-button');
        accordionButtons.forEach(button => {
            button.style.backgroundColor = 'transparent';
        });
    }, []);

    ///sms config
    const GetGroups = async () => {
        try {
            const res = await axios.get(`${api}footer-group`, {
                headers: {
                    'Authorization': 'Bearer ' + authToken,
                }
            });
            if (res.data.status) {
                const data = res.data.GroupData;
                setActiveKeys(data.map((_, index) => String(index)));
                setgroupList(data)
            }
        } catch (err) {
            console.log(err);
        }
    };
    const fetchPages = async () => {
        try {
            const response = await axios.get(`${api}pages-get-title`, {
                headers: {
                    'Authorization': 'Bearer ' + authToken,
                }
            });
            const menus = response.data.pagesData;
            setPages(menus)
        } catch (error) {
            console.error('Failed to fetch payment gateways:', error);
        }
    };
    useEffect(() => {
        GetGroups()
        fetchPages()
    }, []);


    const HandleSubmit = async (e) => {
        e.preventDefault();
        try {
            const payload = {
                title: title,
            };
            let apiUrl = editState ? `${api}footer-group-update/${editId}` : `${api}footer-group-store`;
            const response = await axios.post(apiUrl, payload, {
                headers: {
                    'Authorization': 'Bearer ' + authToken,
                }
            });
            if (response.data.status) {
                GetGroups()
                handleClose()
                successAlert(response.data?.message)
            }
        } catch (error) {
            // console.log(error);
        }
    }
    const handleClose = () => {
        setEditState(false)
        setTitle('');
        setEidtId('');
        setStatus()
        setShow(false)
    }
    const [show, setShow] = useState();


    // link code 
    const [modelShow, setModelShow] = useState();
    const [linkTitle, setLinkTitle] = useState('');
    const [editLinkState, setEditLinkState] = useState('');
    const [groupID, setGroupID] = useState([]);
    const [name, setName] = useState();
    const [attachedPage, setAttachedPage] = useState('');
    const handleEdit = async (data) => {
        if (data) {
            setLinkTitle(data?.title)
            setAttachedPage(data?.page_id)
            setGroupID(data?.id)
            setEditLinkState(true)
            setModelShow(true)
        }
    }

    const handleSave = async () => {
        let url = editLinkState ? `${api}footer-menu-update/${groupID}` : `${api}footer-menu-store`;
        const payload = {
            title: linkTitle,
            footer_group_id: groupID,
            page_id: attachedPage,
        };

        try {
            const response = await axios.post(url, payload);
            if (response.data.status) {
                successAlert('Link' + editLinkState ? 'Update Successfully' : "Added Successfully")
                handleModelClose()
                GetGroups()
            }
        } catch (error) {
            console.error('Error occurred while making the POST request:', error);
            // Handle error appropriately, e.g., display a notification to the user
        }
    };

    const handleModelClose = () => {
        setModelShow(false);
        setEditLinkState(false);
        setLinkTitle('');
        setAttachedPage('');
    };
    const handleAdd = (id, name) => {
        setName(name)
        setGroupID(id)
        setModelShow(true);
    }
    // Handle accordion toggle
    const handleAccordionToggle = (key) => {
        // Check if the key is already active
        const isActive = activeKeys.includes(key);
        if (isActive) {
            // Remove the key to close the accordion item
            setActiveKeys(activeKeys.filter((k) => k !== key));
        } else {
            // Add the key to open the accordion item
            setActiveKeys([...activeKeys, key]);
        }
    };
    return (
        <Row>
            {/* create group  */}
            <Modal show={show} onHide={() => handleClose()}>
                <Modal.Header closeButton>
                    <Modal.Title className="text-center w-100">{editState ? 'Edit' : 'New'} Page</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row>
                            <Col lg="12">
                                <Form.Group className="mb-3 form-group">
                                    <Form.Label className="custom-file-input">Name</Form.Label>
                                    <Form.Control type="text" value={title} placeholder="" onChange={(e) => setTitle(e.target.value)} />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger"
                        onClick={handleClose}
                    >
                        Discard Changes
                    </Button>
                    <Button variant="primary" onClick={HandleSubmit}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* group list  */}
            <Modal show={modelShow} onHide={() => handleModelClose()}>
                <Modal.Header closeButton>
                    <Modal.Title as="h5">{`${editLinkState ? 'Update ' : ' Add '}`}Link To {name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        {/* Link Title Input */}
                        <Form.Group className="mb-3">
                            <Form.Label>Link Title</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter link title"
                                value={linkTitle}
                                onChange={(e) => setLinkTitle(e.target.value)}
                            />
                        </Form.Group>

                        {/* Attach Page Dropdown */}
                        <Form.Group className="mb-3">
                            <Form.Label>Attach page with this link</Form.Label>
                            <Form.Select
                                value={attachedPage}
                                onChange={(e) => setAttachedPage(e.target.value)}
                            >
                                <option>Select a page</option>
                                {pages && pages?.map((page) => (
                                    <option key={page.id} value={page.id}>
                                        {page.title}
                                    </option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleSave}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
            <Col lg="12">
                <Card>
                    <Card.Header className="d-flex justify-content-between">
                        <div className="header-title">
                            <h4 className="card-title">Footer Groups & Menu</h4>
                        </div>
                        <div className="button" onClick={() => setShow(true)}>
                            <h4 className="card-title mb-3">
                                <Button className="me-4 hvr-curl-top-right border-0" >
                                    Add New Group
                                </Button>
                            </h4>
                        </div>
                    </Card.Header>
                </Card>
                <Row className="g-3">
                    {groupList?.map((category, catIndex) => (
                        <Col key={catIndex} md={3}>
                            <Card>
                                <Accordion activeKey={activeKeys}>
                                    <Accordion.Item eventKey={String(catIndex)} className="bg-transparent border-0">
                                        <Accordion.Header onClick={() => handleAccordionToggle(String(catIndex))}>
                                            <div style={{ width: '92%' }} className="d-flex align-items-center justify-content-between">
                                                {category.title}
                                                <div className='d-flex gap-2 align-items-center'>
                                                    <div style={{ cursor: 'pointer' }}
                                                    //onClick={() => handleEdit(item)}
                                                    >
                                                        <svg className="icon-20" width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M11.4925 2.78906H7.75349C4.67849 2.78906 2.75049 4.96606 2.75049 8.04806V16.3621C2.75049 19.4441 4.66949 21.6211 7.75349 21.6211H16.5775C19.6625 21.6211 21.5815 19.4441 21.5815 16.3621V12.3341" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                            <path fillRule="evenodd" clipRule="evenodd" d="M8.82812 10.921L16.3011 3.44799C17.2321 2.51799 18.7411 2.51799 19.6721 3.44799L20.8891 4.66499C21.8201 5.59599 21.8201 7.10599 20.8891 8.03599L13.3801 15.545C12.9731 15.952 12.4211 16.181 11.8451 16.181H8.09912L8.19312 12.401C8.20712 11.845 8.43412 11.315 8.82812 10.921Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                            <path d="M15.1655 4.60254L19.7315 9.16854" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                        </svg>
                                                    </div>
                                                    <div style={{ cursor: 'pointer' }}
                                                    // onClick={() => handleEdit(item)}
                                                    >
                                                        <svg className="icon-20" width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M19.3248 9.46826C19.3248 9.46826 18.7818 16.2033 18.4668 19.0403C18.3168 20.3953 17.4798 21.1893 16.1088 21.2143C13.4998 21.2613 10.8878 21.2643 8.27979 21.2093C6.96079 21.1823 6.13779 20.3783 5.99079 19.0473C5.67379 16.1853 5.13379 9.46826 5.13379 9.46826" stroke="currentColor" strokeWidth="1.5" stroke-linecap="round" strokeLinejoin="round"></path><path d="M20.708 6.23975H3.75" stroke="currentColor" strokeWidth="1.5" stroke-linecap="round" strokeLinejoin="round"></path><path d="M17.4406 6.23973C16.6556 6.23973 15.9796 5.68473 15.8256 4.91573L15.5826 3.69973C15.4326 3.13873 14.9246 2.75073 14.3456 2.75073H10.1126C9.53358 2.75073 9.02558 3.13873 8.87558 3.69973L8.63258 4.91573C8.47858 5.68473 7.80258 6.23973 7.01758 6.23973" stroke="currentColor" strokeWidth="1.5" stroke-linecap="round" strokeLinejoin="round"></path>
                                                            <path d="M19.3248 9.46826C19.3248 9.46826 18.7818 16.2033 18.4668 19.0403C18.3168 20.3953 17.4798 21.1893 16.1088 21.2143C13.4998 21.2613 10.8878 21.2643 8.27979 21.2093C6.96079 21.1823 6.13779 20.3783 5.99079 19.0473C5.67379 16.1853 5.13379 9.46826 5.13379 9.46826" stroke="currentColor" strokeWidth="1.5" stroke-linecap="round" strokeLinejoin="round"></path><path d="M20.708 6.23975H3.75" stroke="currentColor" strokeWidth="1.5" stroke-linecap="round" strokeLinejoin="round"></path><path d="M17.4406 6.23973C16.6556 6.23973 15.9796 5.68473 15.8256 4.91573L15.5826 3.69973C15.4326 3.13873 14.9246 2.75073 14.3456 2.75073H10.1126C9.53358 2.75073 9.02558 3.13873 8.87558 3.69973L8.63258 4.91573C8.47858 5.68473 7.80258 6.23973 7.01758 6.23973" stroke="currentColor" strokeWidth="1.5" stroke-linecap="round" strokeLinejoin="round"></path>
                                                            <path d="M19.3248 9.46826C19.3248 9.46826 18.7818 16.2033 18.4668 19.0403C18.3168 20.3953 17.4798 21.1893 16.1088 21.2143C13.4998 21.2613 10.8878 21.2643 8.27979 21.2093C6.96079 21.1823 6.13779 20.3783 5.99079 19.0473C5.67379 16.1853 5.13379 9.46826 5.13379 9.46826" stroke="currentColor" strokeWidth="1.5" stroke-linecap="round" strokeLinejoin="round"></path><path d="M20.708 6.23975H3.75" stroke="currentColor" strokeWidth="1.5" stroke-linecap="round" strokeLinejoin="round"></path><path d="M17.4406 6.23973C16.6556 6.23973 15.9796 5.68473 15.8256 4.91573L15.5826 3.69973C15.4326 3.13873 14.9246 2.75073 14.3456 2.75073H10.1126C9.53358 2.75073 9.02558 3.13873 8.87558 3.69973L8.63258 4.91573C8.47858 5.68473 7.80258 6.23973 7.01758 6.23973" stroke="currentColor" strokeWidth="1.5" stroke-linecap="round" strokeLinejoin="round"></path>
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>
                                        </Accordion.Header>
                                        <Accordion.Body className="bg-transparent p-0 ">
                                            <Card.Body>
                                                <div className="overflow-scroll pe-5" style={{ maxHeight: '16.5rem' }}>
                                                    {category?.footer_menu?.map((item, index) => (
                                                        <div className="d-flex align-items-center justify-content-between mb-2" key={index}>
                                                            <Form.Label htmlFor={`checkbox-${item?.id}`} className='m-0 h6'>{item.title}</Form.Label>
                                                            <div className='d-flex gap-2 align-items-center'>
                                                                <div style={{ cursor: 'pointer' }} onClick={() => handleEdit(item)}>
                                                                    <EditIcon/>
                                                                </div>
                                                                <div style={{ cursor: 'pointer' }} onClick={() => handleEdit(item)}>
                                                                    <DleteIcon/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                                <div
                                                    style={{ cursor: 'pointer', border: '1px dashed #8A92A6', width: '92%' }}
                                                    className='d-flex justify-content-center flex-column rounded-3 py-3'
                                                    onClick={() => handleAdd(category?.id, category?.title)}
                                                >
                                                    <CustomIconButton
                                                        buttonClasses={'m-0 p-0'}
                                                        iconclass={'m-0 p-0'}
                                                        type="add"
                                                    />
                                                    <span className='text-grey text-center'>Click To Add More Links</span>
                                                </div>
                                            </Card.Body>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Col>
        </Row>
    )
}

export default FooterGroups