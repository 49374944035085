import React, { useState, memo, Fragment, useEffect } from "react";

// React-bootstrap
import { Row, Col, Button, Card } from "react-bootstrap";

//Components
import ProductCard from "../../../e-commerce/components/product-card";
import ProductCardListView from "../../../e-commerce/components/product-card-list-view";

import SwiperCore, { Autoplay } from "swiper";
import "swiper/css";

//Img
import img1 from "../../../../../assets/modules/e-commerce/images/offers/01.png";
import partyImage from "../../../../../assets/modules/e-commerce/images/product/party3.jpg";
import { useMyContext } from "../../../../../Context/MyContextProvider";
import axios from "axios";
import FeatureEvent from "./FeatureEvent";
import EventsContainer from "./EventsContainer";
import { Link } from "react-router-dom";
import FilterOptions from "../../../e-commerce/components/filter-options";


SwiperCore.use([Autoplay]);

const LiveEvents = memo(() => {
    const { api, authToken } = useMyContext();
    const [events, setEvents] = useState([]);
    const [featureEvents, setFeatureEvents] = useState([]);
    const categoryList = [
        { value: 'Business Seminars', label: 'Business Seminars' },
        { value: 'Live Band', label: 'Live Band' },
        { value: 'Live Concert', label: 'Live Concert' },
        { value: 'DJ Night', label: 'DJ Night' },
        { value: 'Garba Night', label: 'Garba Night' },
        { value: 'Food festival', label: 'Food festival' },
        { value: 'Education Festival', label: 'Education Festival' },
        { value: 'Techno Fair', label: 'Techno Fair' },
        { value: 'Real Estate', label: 'Real Estate' },
        { value: 'Automotive Expo', label: 'Automotive Expo' },
        { value: 'Fun fair', label: 'Fun fair' }
    ];

    const fetchEvents = () => {
        axios.get(`${api}events`, {
            headers: {
                'Authorization': 'Bearer ' + authToken,
            }
        }).then((res) => {
            if (res.data.status) {
                setEvents(res.data.events)
            }
        }).catch((err) =>
            console.log(err)
        )
    }
    const GetFeatureEvents = () => {
        axios.get(`${api}feature-event`, {
            headers: {
                'Authorization': 'Bearer ' + authToken,
            }
        }).then((res) => {
            if (res.data.status) {
                setFeatureEvents(res.data.events)
            }
        }).catch((err) =>
            console.log(err)
        )
    }
    useEffect(() => {
        fetchEvents()
        GetFeatureEvents()
    }, []);

    const formattedProductName = (name) => {
        let updated = name.replace(' ', "-");
        return updated
    }
    const HandleCTG = (category) => {
        console.log(category)
    }
    return (
        <Fragment>
            {/* <div className="mt-4 mb-4"> */}
            <Row>
                <Col lg="12">
                    <div className="d-flex justify-content-between align-items-center mb-3 mt-3">
                        <h5 className="mb-0">Trending Items</h5>
                        <div className="d-flex align-items-center gap-3">
                            <div className="d-flex gap-3">
                                <div>
                                    <Link
                                        className="bg-transparent d-flex justify-content-between gap-2 align-items-center iq-custom-collapse"
                                        data-bs-toggle="collapse"
                                        to="#iq-product-filter-02"
                                        role="button"
                                        aria-expanded="true"
                                        aria-controls="iq-product-filter-02"
                                    >
                                        <p className="mb-0">Type</p>
                                        <i className="right-icon">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="18"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M9 5l7 7-7 7"
                                                ></path>
                                            </svg>
                                        </i>
                                    </Link>
                                    <div className="collapse mt-2" id="iq-product-filter-02">
                                        {categoryList?.map((item,index)=>
                                        <FilterOptions
                                            uniqueName="type"
                                            id={item?.value}
                                            productName={item?.value}
                                            HandleOnchange={HandleCTG}
                                        />
                                        )}
                                    </div>
                                </div>
                            </div>
                            <ul
                                className="nav nav-pills mb-0 iq-product-filter d-flex bg-transparent align-items-center"
                                id="pills-tab"
                                role="tablist"
                            >
                                <li className="nav-item dropdown d-none d-xl-block">
                                    <div className="form-group input-group mb-0 search-input w-100 ">
                                        <input
                                            type="search"
                                            className="form-control"
                                            placeholder="Search..."
                                        />
                                        <span className="input-group-text">
                                            <svg
                                                width="18"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <circle
                                                    cx="11.7669"
                                                    cy="11.7666"
                                                    r="8.98856"
                                                    stroke="currentColor"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                ></circle>
                                                <path
                                                    d="M18.0186 18.4851L21.5426 22"
                                                    stroke="currentColor"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                ></path>
                                            </svg>
                                        </span>
                                    </div>
                                </li>
                                <li className="nav-item ms-2" role="presentation">
                                    <Button
                                        bsPrefix="nav-link btn btn-sm btn-icon rounded-pill active"
                                        size="sm"
                                        id="grid-view-tab"
                                        data-bs-toggle="pill"
                                        data-bs-target="#pills-grid-view"
                                        to="#"
                                        role="tab"
                                        aria-controls="pills-grid-view"
                                        aria-selected="true"
                                    >
                                        <svg
                                            width="16"
                                            className="icon-16"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                opacity="0.4"
                                                d="M16.0756 2H19.4616C20.8639 2 22.0001 3.14585 22.0001 4.55996V7.97452C22.0001 9.38864 20.8639 10.5345 19.4616 10.5345H16.0756C14.6734 10.5345 13.5371 9.38864 13.5371 7.97452V4.55996C13.5371 3.14585 14.6734 2 16.0756 2Z"
                                                fill="currentColor"
                                            ></path>
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M4.53852 2H7.92449C9.32676 2 10.463 3.14585 10.463 4.55996V7.97452C10.463 9.38864 9.32676 10.5345 7.92449 10.5345H4.53852C3.13626 10.5345 2 9.38864 2 7.97452V4.55996C2 3.14585 3.13626 2 4.53852 2ZM4.53852 13.4655H7.92449C9.32676 13.4655 10.463 14.6114 10.463 16.0255V19.44C10.463 20.8532 9.32676 22 7.92449 22H4.53852C3.13626 22 2 20.8532 2 19.44V16.0255C2 14.6114 3.13626 13.4655 4.53852 13.4655ZM19.4615 13.4655H16.0755C14.6732 13.4655 13.537 14.6114 13.537 16.0255V19.44C13.537 20.8532 14.6732 22 16.0755 22H19.4615C20.8637 22 22 20.8532 22 19.44V16.0255C22 14.6114 20.8637 13.4655 19.4615 13.4655Z"
                                                fill="currentColor"
                                            ></path>
                                        </svg>
                                    </Button>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="tab-content" id="pills-tabContent">
                        <div
                            className="tab-pane fade show active"
                            id="pills-grid-view"
                            role="tabpanel"
                            aria-labelledby="grid-view-tab"
                        >
                            <EventsContainer />
                        </div>
                    </div>
                </Col>
                <FeatureEvent featureEvents={featureEvents} formattedProductName={formattedProductName} partyImage={partyImage} />
            </Row>
            {/* </div> */}
        </Fragment>
    );
});
LiveEvents.displayName = "LiveEvents";
export default LiveEvents;
