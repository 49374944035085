import React, { Fragment, memo, useState, useEffect } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import { useMyContext } from "../../../../Context/MyContextProvider";
import DOMPurify from 'dompurify';
const MapIcon = () => (
    <svg
        width="30"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M1 6V22L8 18L16 22L23 18V2L16 6L8 2L1 6Z"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M16 6V22"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M8 2V18"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)
const PhoneIcon = () => (
    <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M21 15.9201V18.9201C21.0011 19.1986 20.9441 19.4743 20.8325 19.7294C20.7209 19.9846 20.5573 20.2137 20.3521 20.402C20.1468 20.5902 19.9046 20.7336 19.6407 20.8228C19.3769 20.912 19.0974 20.9452 18.82 20.9201C15.7428 20.5857 12.787 19.5342 10.19 17.8501C7.77382 16.3148 5.72533 14.2663 4.18999 11.8501C2.49997 9.2413 1.44824 6.27109 1.11999 3.1801C1.095 2.90356 1.12787 2.62486 1.21649 2.36172C1.30512 2.09859 1.44756 1.85679 1.63476 1.65172C1.82196 1.44665 2.0498 1.28281 2.30379 1.17062C2.55777 1.05843 2.83233 1.00036 3.10999 1.0001H6.10999C6.5953 0.995321 7.06579 1.16718 7.43376 1.48363C7.80173 1.80008 8.04207 2.23954 8.10999 2.7201C8.23662 3.68016 8.47144 4.62282 8.80999 5.5301C8.94454 5.88802 8.97366 6.27701 8.8939 6.65098C8.81415 7.02494 8.62886 7.36821 8.35999 7.6401L7.08999 8.9101C8.51355 11.4136 10.5864 13.4865 13.09 14.9101L14.36 13.6401C14.6319 13.3712 14.9751 13.1859 15.3491 13.1062C15.7231 13.0264 16.1121 13.0556 16.47 13.1901C17.3773 13.5286 18.3199 13.7635 19.28 13.8901C19.7658 13.9586 20.2094 14.2033 20.5265 14.5776C20.8437 14.9519 21.0122 15.4297 21 15.9201Z"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)
const DotICon = () => (
    <svg
        className="me-2 text-primary"
        width="7"
        height="8"
        viewBox="0 0 7 8"
        fill="none"
    >
        <circle
            cx="3.5"
            cy="4"
            r="3.5"
            fill="currentColor"
        ></circle>
    </svg>
)
const MainFooter = memo(() => {
    const { api,createSlug } = useMyContext()
    const [date, setDate] = useState();
    const [groups, setGroups] = useState([]);
    const [data, setData] = useState();

    const getFooterData = async () => {
        try {
            const res = await axios.get(`${api}footer-group`);
            if (res.data.status) {
                const configData = res.data.FooterData;
                const groupsData = res.data.GroupData;
                setData(configData)
                setGroups(groupsData)
            }
        } catch (err) {
            console.log(err);
        }
    };
    const getYear = () => setDate(new Date().getFullYear());
    useEffect(() => {
        getFooterData()
        getYear();
    }, []);

    return (
        <Fragment>
            <footer>
                <div className="py-5" style={{ background: '#17132E' }}>
                    <Container>
                        <Row>
                            <Col md={4}>
                                <Link
                                    to="/"
                                    className="navbar-brand  d-flex align-items-center"
                                >
                                    <Image src={data?.logo || data?.footer_logo} width={200} loading="lazy" />
                                </Link>
                                <div className="d-flex align-items-center mt-2 mb-3">
                                    <MapIcon />
                                    <p className="ms-4 mb-0 text-white">
                                        {data?.footer_address}
                                    </p>
                                </div>
                                <div className="d-flex align-items-center">
                                    <PhoneIcon />
                                    <p className="ms-4 mb-0 text-white">{data?.footer_contact}</p>
                                </div>
                            </Col>
                            {groups && groups.map((item, i) => {
                                return (
                                    <Col md={2} key={i} className="mt-md-0 mt-4">
                                        <h5 className="mb-4 text-white">{item.title}</h5>
                                        <ul className="m-0 p-0 list-unstyled text-white">
                                            {item?.footer_menu.map((link, j) => (
                                                 <Link to={`/pages/${createSlug(link.title)}`} className="text-white">
                                                 <li className="mb-3">
                                                        <DotICon />
                                                        {link.title}
                                                    </li>
                                             </Link>
                                            ))}

                                        </ul>
                                    </Col>
                                )
                            })}
                            {/* <Col md={2} className="mt-md-0 mt-4">
                                <h5 className="mb-4 text-white">Help</h5>
                                <ul className="m-0 p-0 list-unstyled text-white">
                                    <Link to={'terms-condition'} className="text-white">
                                        <li className="mb-3">
                                            <svg
                                                className="me-2 text-primary"
                                                width="7"
                                                height="8"
                                                viewBox="0 0 7 8"
                                                fill="none"
                                            >
                                                <circle
                                                    cx="3.5"
                                                    cy="4"
                                                    r="3.5"
                                                    fill="currentColor"
                                                ></circle>
                                            </svg>
                                            Terms & Condition
                                        </li>
                                    </Link>
                                    <Link to={'refund-policy'} className="text-white">
                                        <li className="mb-3">
                                            <svg
                                                className="me-2 text-primary"
                                                width="7"
                                                height="8"
                                                viewBox="0 0 7 8"
                                                fill="none"
                                            >
                                                <circle
                                                    cx="3.5"
                                                    cy="4"
                                                    r="3.5"
                                                    fill="currentColor"
                                                ></circle>
                                            </svg>
                                            Refunds & Returns Policy
                                        </li>
                                    </Link>
                                    <Link to={'privacy-policy'} className="text-white">
                                        <li>
                                            <svg
                                                className="me-2 text-primary"
                                                width="7"
                                                height="8"
                                                viewBox="0 0 7 8"
                                                fill="none"
                                            >
                                                <circle
                                                    cx="3.5"
                                                    cy="4"
                                                    r="3.5"
                                                    fill="currentColor"
                                                ></circle>
                                            </svg>
                                            Privacy Policy
                                        </li>
                                    </Link>
                                </ul>
                            </Col> */}
                            <Col md={4} className="md-4 mt-md-0 mt-4">
                                <h5 className="mb-4 text-white">Event Location</h5>
                                <div className="d-flex align-items-center mt-2 mb-3">
                                    <MapIcon />
                                    <p className="ms-4 mb-0 text-white">
                                        {data?.footer_address}
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="footer-bottom" style={{ background: '#17132E' }}>
                    <Container className=" py-4 footer-border">
                        <Row>
                            <Col md={12} className="text-center text-white">
                                <p className="mb-0 d-flex gap-2 justify-content-center">
                                    © {date} {data?.site_credit && (
                                        <span
                                            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data?.site_credit) }}
                                        />
                                    )}
                                </p>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </footer>
        </Fragment>
    );
});

export default MainFooter;
