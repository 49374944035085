import React from 'react'
import { Row } from 'react-bootstrap';
import BookingTickets from './BookingProcess.js/BookingTickets';
import BookingSummary from './BookingProcess.js/BookingSummary';
import DynamicAttendeeForm from './DynamicAttendeeForm';
const CheckOutData = ({
    event,
    selectedTickets,
    currentStep,
    error,
    isMobile,
    resetCounterTrigger,
    getTicketCount,
    getCurrencySymbol,
    code,
    setCode,
    applyPromode,
    discount,
    appliedPromoCode,
    ticketCurrency,
    subtotal,
    handleRemovePromocode,
    totalDiscount,
    baseAmount,
    centralGST,
    totalTax,
    grandTotal,
    handlePayment,
    categoryData,
    attendeeState,
    setAttendeeState,
    isAttendeeRequired,
    AttendyView,
    setAttendees,
    setDisable,
    disable

}) => {
    return (
        <div id="checkout" className={`iq-product-tracker-card b-0 ${currentStep === 'checkout' ? 'show' : ''}`}>
            <Row>
                {
                    attendeeState ?
                        <DynamicAttendeeForm
                            category_id={categoryData?.categoryData?.id}
                            setDisable={setDisable}
                            disable={disable}
                            AttendyView={AttendyView}
                            setAttendees={setAttendees}
                            apiData={categoryData?.customFieldsData}
                            setAttendeeState={setAttendeeState}
                            selectedTickets={selectedTickets}
                            quantity={selectedTickets?.quantity}
                        />
                        :
                        <BookingTickets

                            error={error}
                            event={event}
                            isMobile={isMobile}
                            resetCounterTrigger={resetCounterTrigger}
                            getTicketCount={getTicketCount}
                            selectedTickets={selectedTickets}
                            getCurrencySymbol={getCurrencySymbol}
                        />
                }

                <BookingSummary
                    disable={disable}
                    code={code}
                    isAttendeeRequired={isAttendeeRequired}
                    setCode={setCode}
                    applyPromode={applyPromode}
                    discount={discount}
                    appliedPromoCode={appliedPromoCode}
                    ticketCurrency={ticketCurrency}
                    subtotal={subtotal}
                    handleRemovePromocode={handleRemovePromocode}
                    totalDiscount={totalDiscount}
                    baseAmount={baseAmount}
                    centralGST={centralGST}
                    totalTax={totalTax}
                    grandTotal={grandTotal}
                    isMobile={isMobile}
                    handlePayment={handlePayment}
                />
            </Row>
        </div>
    )
}

export default CheckOutData