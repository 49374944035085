import React, { useState } from 'react'
import { Modal, Button, Row, Col, Card } from 'react-bootstrap';
import { CustomCheckbox } from '../../../../CustomComponents/CustomFormFields';
import { useMyContext } from '../../../../../../../Context/MyContextProvider';
const AttendySugettion = ({ requiredFields, data, showAddAttendeeModal, setShowAddAttendeeModal, setAttendeesList, quantity, openAddModal,totalAttendee }) => {
    const [selectedAttendees, setSelectedAttendees] = useState([]);
    const { ErrorAlert } = useMyContext()
    const handleSelectAttendee = (e, attendee) => {
        const isChecked = e.target.checked;
        const missingFields = requiredFields
            .filter(field => attendee[field] == null)
            .map(field => field);
        setSelectedAttendees((prevSelected) => {
            if (isChecked) {
                if (prevSelected.length < quantity) {
                    const updatedSelected = [...prevSelected, attendee];
                    setAttendeesList((prevList) => [
                        ...prevList,
                        { ...attendee, missingFields }
                    ]);

                    return updatedSelected;
                } else {
                    ErrorAlert('Maximum number of attendees reached');
                    return prevSelected;
                }
            } else if (!isChecked) {
                const updatedSelected = prevSelected.filter((item) => item !== attendee);
                setAttendeesList((prevList) =>
                    prevList.filter((item) => item !== attendee)
                );

                return updatedSelected;
            }
            return prevSelected;
        });
    };


    const handleConfirmAttendees = () => {
        setShowAddAttendeeModal(false);
    };
    const HandleClose = () => {

        if(quantity !== totalAttendee){
            openAddModal(true)
        }
        setShowAddAttendeeModal(false);
    }
    return (
        <Modal show={showAddAttendeeModal} onHide={() => HandleClose()} size='xl'>
            <Modal.Header>
                <div className="d-flex justify-content-between w-100">
                    <div>
                        <Modal.Title>Suggested Attendees</Modal.Title>
                        <span className='text-muted'>&nbsp;(You can select maximumn {quantity} attendees)</span>
                    </div>
                    <div className=''>
                        <Button variant="secondary" className='me-2' onClick={() => HandleClose()}>
                            Add New Atttendee
                        </Button>
                        <Button variant="primary" onClick={handleConfirmAttendees}>
                            Confirm Selection
                        </Button>
                    </div>
                </div>

            </Modal.Header>
            <Modal.Body className="overflow-auto" style={{ maxHeight: '50rem' }}>
                <Row>
                    {data.map((attendee, index) => (
                        <Col md={4} key={index}>
                            <Card>
                                <Card.Body className='p-0'>
                                    <label className="inner-set p-2 rounded-3 d-flex align-items-center gap-3 custom-dotted-border cursor-pointer">
                                        <CustomCheckbox
                                            disabled={
                                                selectedAttendees.length >= quantity &&
                                                !selectedAttendees.includes(attendee)
                                            }
                                            validationMessage="Checkbox is required"
                                            onChange={(e) => handleSelectAttendee(e, attendee)}
                                        />
                                        <div className="custom-checkbox-label">
                                            {Object.entries(attendee)
                                                .slice(1, 4)
                                                .map(([key, value], index) => (
                                                    <div key={index}>
                                                        <strong>{key}:</strong> {value}
                                                    </div>
                                                ))}
                                        </div>
                                    </label>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}

                </Row>
            </Modal.Body>
        </Modal>
    )
}

export default AttendySugettion