import React, { useEffect, useRef, useState } from 'react'
import { Button, Card, Col, Form, Modal, Row } from 'react-bootstrap'
import useDataTable from '../../../../components/hooks/useDatatable';
import axios from 'axios';
import { useMyContext } from '../../../../Context/MyContextProvider';
import JoditEditor from 'jodit-react';
import DOMPurify from 'dompurify';
const Pages = () => {
    const { api, successAlert, UserData, authToken } = useMyContext();
    const [title, setTitle] = useState();
    const [content, setContent] = useState();
    const [pageList, setPageList] = useState();
    const [editState, setEditState] = useState(false)
    const [status, setStatus] = useState();
    const [editId, setEidtId] = useState('');
    const columns = [
        { data: "title", title: "Page Title" },
        {
            data: null,
            orderable: false,
            searchable: false,
            title: "Action",
            render: function (data) {
                return `<div class="flex align-items-center list-user-action">
                                  <button class="btn btn-sm btn-icon" data-bs-toggle="tooltip" data-bs-placement="top" title="Print" data-id=${data?.id} data-method="Edit" data-table="action">
                                     <svg fill="none" xmlns="http://www.w3.org/2000/svg" class="icon-20" width="20" height="32" viewBox="0 0 24 24"><path d="M11.4925 2.78906H7.75349C4.67849 2.78906 2.75049 4.96606 2.75049 8.04806V16.3621C2.75049 19.4441 4.66949 21.6211 7.75349 21.6211H16.5775C19.6625 21.6211 21.5815 19.4441 21.5815 16.3621V12.3341" stroke="currentColor" strokeWidth="1.5" stroke-linecap="round" strokeLinejoin="round"></path><path fillRule="evenodd" clipRule="evenodd" d="M8.82812 10.921L16.3011 3.44799C17.2321 2.51799 18.7411 2.51799 19.6721 3.44799L20.8891 4.66499C21.8201 5.59599 21.8201 7.10599 20.8891 8.03599L13.3801 15.545C12.9731 15.952 12.4211 16.181 11.8451 16.181H8.09912L8.19312 12.401C8.20712 11.845 8.43412 11.315 8.82812 10.921Z" stroke="currentColor" strokeWidth="1.5" stroke-linecap="round" strokeLinejoin="round"></path><path d="M15.1655 4.60254L19.7315 9.16854" stroke="currentColor" strokeWidth="1.5" stroke-linecap="round" strokeLinejoin="round"></path></svg>
                                  </button>
                                   <button class="btn btn-sm btn-icon" data-bs-toggle="tooltip" data-bs-placement="top" title="Peview" data-id=${data?.id} data-method="Peview" data-table="action">
                                         <svg fill="none" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M15.1614 12.0531C15.1614 13.7991 13.7454 15.2141 11.9994 15.2141C10.2534 15.2141 8.83838 13.7991 8.83838 12.0531C8.83838 10.3061 10.2534 8.89111 11.9994 8.89111C13.7454 8.89111 15.1614 10.3061 15.1614 12.0531Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            <path fillRule="evenodd" clipRule="evenodd" d="M11.998 19.355C15.806 19.355 19.289 16.617 21.25 12.053C19.289 7.48898 15.806 4.75098 11.998 4.75098H12.002C8.194 4.75098 4.711 7.48898 2.75 12.053C4.711 16.617 8.194 19.355 12.002 19.355H11.998Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                      </button>
                                  <button class="btn btn-sm btn-icon" data-bs-toggle="tooltip" data-bs-placement="top" title="Delete" data-id=${data?.id} data-method="Delete" data-table="action">
                                     <svg fill="none" xmlns="http://www.w3.org/2000/svg" class="icon-20" width="20" height="32" viewBox="0 0 24 24"><path d="M19.3248 9.46826C19.3248 9.46826 18.7818 16.2033 18.4668 19.0403C18.3168 20.3953 17.4798 21.1893 16.1088 21.2143C13.4998 21.2613 10.8878 21.2643 8.27979 21.2093C6.96079 21.1823 6.13779 20.3783 5.99079 19.0473C5.67379 16.1853 5.13379 9.46826 5.13379 9.46826" stroke="currentColor" strokeWidth="1.5" stroke-linecap="round" strokeLinejoin="round"></path><path d="M20.708 6.23975H3.75" stroke="currentColor" strokeWidth="1.5" stroke-linecap="round" strokeLinejoin="round"></path><path d="M17.4406 6.23973C16.6556 6.23973 15.9796 5.68473 15.8256 4.91573L15.5826 3.69973C15.4326 3.13873 14.9246 2.75073 14.3456 2.75073H10.1126C9.53358 2.75073 9.02558 3.13873 8.87558 3.69973L8.63258 4.91573C8.47858 5.68473 7.80258 6.23973 7.01758 6.23973" stroke="currentColor" strokeWidth="1.5" stroke-linecap="round" strokeLinejoin="round"></path></svg>
                                  </button>
                                  </div>
                              `;
            },
        },
    ];
    const tableRef = useRef(null);
    useDataTable({
        tableRef: tableRef,
        columns: columns,
        data: pageList,
        actionCallback: (data) => {
            switch (data.method) {
                case "Edit":
                    handleEdit(data?.id);
                    break;
                case "Peview":
                    HandlePreview(data?.id);
                    break;
                case "Delete":
                    alert(data?.id);
                    break;
                default:
                    break;
            }
        },
        // isFooter: true,
    });
    const [templatePreview, setTemplatePreview] = useState('')
    const [preview, setPreview] = useState(false);
    const HandlePreview = (id) => {
        try {
            const template = pageList.find((item) => item.id === id);
            if (template) {
                const sanitizedHTML = DOMPurify.sanitize(template.content); // Sanitize the HTML
                setTemplatePreview(sanitizedHTML); // Assuming 'body' contains the HTML string
                setPreview(true);
            } else {
                throw new Error('Template not found');
            }
        } catch (err) {
            setPreview(false);
        }
    };
    const handlePreviewClose = () => {
        setPreview(false);
    }
    const handleEdit = async (id) => {
        let data = pageList?.find((item) => item?.id === id)
        // let status = data.status
        setStatus(data.status === 1 ? true : false);
        // setStatus(data.status)
        setEditState(true)
        setContent(data?.content);
        setTitle(data?.title);
        setEidtId(data?.id);
        setShow(true)
    }


    ///sms config
    const GetPages = async () => {
        try {
            const res = await axios.get(`${api}pages-list`, {
                headers: {
                    'Authorization': 'Bearer ' + authToken,
                }
            });
            if (res.data.status) {
                const data = res.data.pagesData;
                setPageList(data)
            }
        } catch (err) {
            console.log(err);
        }
    };
    useEffect(() => {
        GetPages()
    }, []);


    const HandleSubmit = async () => {
        try {
            const payload = {
                title: title,
                content: content,
                status: status === true ? 1 : 0,
            };
            let apiUrl = editState ? `${api}pages-update/${editId}` : `${api}pages-store`;
            const response = await axios.post(apiUrl, payload, {
                headers: {
                    'Authorization': 'Bearer ' + authToken,
                }
            });
            if (response.data.status) {
                GetPages()
                handleClose()
                successAlert(response.data?.message)
            }
        } catch (error) {
            // console.log(error);
        }
    }
    const handleClose = () => {
        setEditState(false)
        setContent('');
        setTitle('');
        setEidtId('');
        setStatus()
        setShow(false)
    }
    const [show, setShow] = useState();
    return (
        <Row>
            <Modal show={preview} onHide={handlePreviewClose} size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title as="h5">Preview</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {preview && templatePreview && (
                        // templatePreview
                        <div
                            dangerouslySetInnerHTML={{ __html: templatePreview }}
                        />
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger"
                        onClick={handlePreviewClose}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={show} onHide={() => handleClose()} size='xl'>
                <Modal.Header closeButton>
                    <Modal.Title className="text-center w-100">{editState ? 'Edit' : 'New'} Page</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row>
                            <Col lg="12">
                                <Form.Group className="mb-3 form-group">
                                    <Form.Label className="custom-file-input">Name</Form.Label>
                                    <Form.Control type="text" value={title} placeholder="" onChange={(e) => setTitle(e.target.value)} />
                                </Form.Group>
                            </Col>
                            <Col lg="12">
                                <Form.Group className="mb-3 form-group">
                                    <Form.Label className="custom-file-input">Body</Form.Label>
                                    <JoditEditor
                                        tabIndex={1}
                                        value={content}
                                        onChange={(newContent) => setContent(newContent)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg="6">
                                <Form.Label className="custom-file-input">Page Status</Form.Label>
                                <Row>
                                    <Col lg="6" className='d-flex gap-3'>
                                        <div className="form-radio form-check">
                                            <Form.Check.Input
                                                type="radio"
                                                id="customRadio8"
                                                name="payment"
                                                className="me-2"
                                                checked={status === true}
                                                value={true}
                                                onChange={(e) => setStatus(true)}
                                            />
                                            <Form.Label
                                                className="custom-control-label"
                                                htmlFor="customRadio8"
                                            >
                                                {" "}
                                                Live
                                            </Form.Label>
                                        </div>
                                        <div className="form-radio form-check">
                                            <Form.Check.Input
                                                type="radio"
                                                id="customRadio8"
                                                name="payment"
                                                className="me-2"
                                                value={false}
                                                checked={status === false}
                                                onChange={(e) => setStatus(false)}
                                            />
                                            <Form.Label
                                                className="custom-control-label"
                                                htmlFor="customRadio8"
                                            >
                                                {" "}
                                                Disabled
                                            </Form.Label>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger"
                        onClick={handleClose}
                    >
                        Discard Changes
                    </Button>
                    <Button variant="primary" onClick={HandleSubmit}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
            <Col lg="12">
                <Card>
                    <Card.Header className="d-flex justify-content-between">
                        <div className="header-title">
                            <h4 className="card-title">Pages</h4>
                        </div>
                        <div className="button">
                            <h4 className="card-title">
                                <Button className="me-4 hvr-curl-top-right border-0" onClick={() => setShow(true)}>
                                    Add New Page
                                </Button>
                            </h4>
                        </div>
                    </Card.Header>
                    <Card.Body>
                        <table
                            ref={tableRef}
                            className="table dataTable"
                            data-toggle="data-table"
                        ></table>{" "}
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    )
}

export default Pages